import {
  format,
  differenceInMinutes,
  formatDuration,
  differenceInDays,
  differenceInHours,
  formatDistanceToNow,
  subDays,
} from "date-fns";
import { CollectionInterface } from "../interfaces/collection";
import { Owner, Token } from "../interfaces/token";

export const checkOwner = (walletAddress: string | null, owner: string) => {
  return owner.toLowerCase() === walletAddress?.toLowerCase();
};

export const checkOfferMaker = (
  walletAddress: string | null,
  offerMaker: string
) => {
  return offerMaker.toLowerCase() === walletAddress?.toLowerCase();
};

export const displayOwner = (walletAddress: string | null, owner: string) => {
  return owner.toLowerCase() === walletAddress?.toLowerCase() ? "You" : owner;
};

export const handleTokenName = (token: Token) => {
  return token.metadata?.name
    ? token.metadata.name.slice(0, 16)
    : token.token_id.toString().slice(0, 5);
};

export const handleImageCard = (
  token: Token,
  collection?: CollectionInterface
) => {
  if (token.metadata?.image) {
    return token.metadata?.image;
  }
  if (collection?.image_url) {
    return collection.image_url;
  }
  return "/noContent.svg";
};

export const handleImageCardOverlay = (token: Token) => {
  if (token.metadata?.image) {
    return true;
  }
  return false;
};

export const validateChainId = (chainId: string | null) => {
  return chainId == process.env.REACT_APP_CHAIN_ID_DEV;
};

export const calcFloorDifference = (floorPrice: string, price: string) => {
  if (floorPrice === "0") {
    return "100% above";
  }

  const result = 1 - parseFloat(price) / parseFloat(floorPrice);
  if (Math.sign(result) == 1) {
    return `${result} above`;
  } else if (Math.sign(result) == 1) {
    return `${result} below`;
  } else if (Math.sign(result) == 0) {
    return `0%`;
  }
};

export const getShortAddress = (collectionAddress: string | undefined) => {
  if (collectionAddress) {
    return collectionAddress.slice(0, 5) + "...";
  }
};

export const getShortenCollectionName = (
  collectionName: string,
  length: number
) => {
  if (collectionName.length < length) return collectionName;
  const dots = "...";
  return collectionName.substring(0, length - dots.length).concat(dots);
};

export const dateDisplay = (date: Date) => {
  if (date) {
    return format(new Date(date), "MMMM dd, yyyy");
  }
};

export const displayLeftAuctionTime = (startTime: number, endTime: number) => {
  const startTimeDate = new Date(startTime * 1000);
  const endTimeDate = new Date(endTime * 1000);
  const leftTimeInDays = differenceInDays(endTimeDate, startTimeDate);

  if (leftTimeInDays == 0) {
    const leftTimeHours = differenceInHours(endTimeDate, startTimeDate);
    if (leftTimeHours == 0) {
      const leftTimeInMinutes = differenceInMinutes(endTimeDate, startTimeDate);
      return formatDuration({
        minutes: leftTimeInMinutes,
      });
    }
    return formatDuration({
      hours: leftTimeHours,
    });
  }

  return formatDuration({
    days: leftTimeInDays,
  });
};

export const calPastDate = (startTime: string) => {
  const startTimeDate = new Date(startTime);
  const localStartTime = new Date(
    startTimeDate.getTime() - startTimeDate.getTimezoneOffset() * 60000
  );
  const distance = formatDistanceToNow(localStartTime, { addSuffix: true });
  return distance;
};

export const handleBannerImage = (collection: CollectionInterface) => {
  if (collection.banner_image_url) {
    return collection.banner_image_url;
  } else if (collection.image_url) return collection.image_url;
  return "/noContent.svg";
};
