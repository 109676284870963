import {
  Autocomplete,
  FilterOptionsState,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import SearchIcon from "@mui/icons-material/Search";
import "./search.scss";
import { useNavigate } from "react-router-dom";
import { get } from "../../fetch/fetchApi";
import { useQuery } from "react-query";
import { getShortenCollectionName } from "../../utils/util";
import { CollectionInterface } from "../../interfaces/collection";
import { useDebounce } from "use-debounce";
import { convertWeiToETH } from "../../utils/web3Utils";

const SearchCollection = () => {
  const [inputValue, setInputValue] = useState("");
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);

  const [debouncedValue] = useDebounce(inputValue, 500);
  const { data } = useQuery(
    ["searchData", debouncedValue],
    async () => {
      const response = await get(
        `collections/v1/search?text=${debouncedValue}`
      );
      return response;
    },
    {
      enabled: Boolean(debouncedValue),
    }
  );

  const handleInputChange = (event: any, value: any) => {
    setInputValue(value);
    setIsLoadingData(true);
    setOpen(true);
  };

  useEffect(() => {
    if (data) {
      setSearchResults(data);
      if (searchResults.length > 0) {
        setIsLoadingData(false);
      }
    }
  }, [data, searchResults]);

  const handleImage = (collecition: CollectionInterface) => {
    if (!collecition.image_url || collecition.image_url === "empty") {
      return "/noContent.svg";
    }
    return collecition.image_url;
  };
  const keepAllOptions = (options: any[], state: FilterOptionsState<any>) => {
    return options;
  };
  const handleOnClickNavigate = (contract: string) => {
    setOpen(false);
    navigate(`/collection/${contract}`);
  };

  return (
    <Autocomplete
      sx={{
        width: "40rem",
        color: "white",
        marginLeft: "2rem",
        marginBottom: "0.5rem",
      }}
      options={searchResults}
      loading={isLoadingData}
      inputValue={inputValue}
      filterOptions={keepAllOptions}
      getOptionLabel={(option: any): string => option}
      onInputChange={handleInputChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for collection"
          margin="normal"
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start" sx={{ color: "white" }}>
                <SearchIcon sx={{ color: "white" }} />
              </InputAdornment>
            ),
            sx: { color: "white" },
          }}
        />
      )}
      renderOption={(props, option, i) => (
        <div
          className="items-search-wrapper"
          onClick={() => handleOnClickNavigate(option.collection_id)}
        >
          <div className="image-url-search">
            <img src={handleImage(option)} alt="" className="image-search" />
          </div>
          <div className="name-and-items">
            <div className="search-name">
              {getShortenCollectionName(option.name, 20)}
            </div>
            <div className="search-items">{option.total_tokens} ITEMS</div>
          </div>
          <div className="eth-price">
            <div className="eth-icon">
              <img
                src="/eth.svg"
                alt=""
                style={{ width: "18px", height: "18px" }}
              />
            </div>
            <div className="price">
              {option.floor_price ? convertWeiToETH(option.floor_price) : 0}
            </div>
          </div>
        </div>
      )}
      noOptionsText={isLoadingData ? "Loading..." : "No options"}
    />
  );
};

export default SearchCollection;
