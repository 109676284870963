import { useMetaMask } from "metamask-react";
import { FC, useState } from "react";
import Web3 from "web3";
import { checkOwner } from "../../../utils/util";
import { CollectionInterface } from "../../../interfaces/collection";
import { ItemSeller, Offer } from "../../../interfaces/OfferTypes";
import { Token } from "../../../interfaces/token";
import OffersComponent from "../../tokenDetailsPage/offers/offers";
import VenusMarketPlace from "../../../contracts/marketplace.json";
import { AbiItem } from "web3-utils";
import {
  isApproveERC1155Collection,
  isApproveERC721Collection,
  setApproveERC1155Collection,
  setApproveERC721Collection,
} from "../../../utils/web3Utils";
import { addressesByNetwork } from "../../../constants/addresses";
import ApproveOfferDialog from "./approveOfferDialog";
import GlobalDialog from "../../globalDialog/globalDialog";
import { TokenType } from "../../../interfaces/tokenTypeEnum";
import { useMutation, useQueryClient } from "react-query";
import { ItemActivityRequest } from "../../../interfaces/itemActivity";
import { post } from "../../../fetch/fetchApi";
import { getPriceHistory } from "../../../queries/tokenDetailsQueries";

const OfferDetailsContainer: FC<OfferDetailsContainerProps> = (
  props: OfferDetailsContainerProps
) => {
  const { collection, token } = props;
  const { account, chainId, switchChain } = useMetaMask();
  const queryClient = useQueryClient();

  const [showApproveOfferDialog, setShowApproveOfferDialog] =
    useState<boolean>(false);
  const [allowAccessTokens, setAllowAccessTokens] = useState<boolean>(false);
  const [saleCompleted, setSaleCompleted] = useState<boolean>(false);
  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);

  const title = "Error";
  const content = "We couldnt approve the offer, please try again";

  const onApproveOffer = async (offer: Offer, itemSeller: ItemSeller) => {
    const web3 = new Web3(window.ethereum);

    if (itemSeller.seller !== account) {
      //TODO: navigate to login / render dialog of login first
      return;
    }

    if (chainId !== process.env.REACT_APP_CHAIN_ID_PROD) {
      await switchChain("0x1");
      return;
    }

    try {
      setShowApproveOfferDialog(true);
      const venusMarketPlaceContract = new web3.eth.Contract(
        VenusMarketPlace.abi as AbiItem[],
        addressesByNetwork(chainId).VENUS_MARKETPLACE
      );
      if (
        collection.token_type === TokenType.ERC721 &&
        !(await isApproveERC721Collection(account, offer.collection))
      ) {
        await setApproveERC721Collection(account, offer.collection);
      } else if (
        collection.token_type === TokenType.ERC1155 &&
        !(await isApproveERC1155Collection(account, offer.collection))
      ) {
        await setApproveERC1155Collection(account, offer.collection);
      }
      setAllowAccessTokens(true);

      const approveOfferEstGas = await venusMarketPlaceContract.methods
        .sellWithWETH(offer, itemSeller)
        .estimateGas({ from: account });

      const functionData = venusMarketPlaceContract.methods
        .sellWithWETH(offer, itemSeller)
        .encodeABI();
      const approveOffer = {
        to: addressesByNetwork(chainId).VENUS_MARKETPLACE,
        data: functionData,
        gas: approveOfferEstGas,
        from: account,
      };
      const signedTx = await web3.eth.sendTransaction(approveOffer);
      if (signedTx) {
        queryClient.invalidateQueries([
          "get-token-activity",
          `${collection}/${token}`,
        ]);
        queryClient.invalidateQueries([
          "get-token-by-collection-id",
          collection,
          token,
        ]);
        queryClient.invalidateQueries([
          getPriceHistory,
          `${collection}/${token}`,
        ]);

        setSaleCompleted(true);
      }
    } catch (err) {
      setShowApproveOfferDialog(false);
      setAllowAccessTokens(false);
      setErrorMetaMask(true);
      setSaleCompleted(false);
    }
  };

  return (
    <>
      <OffersComponent
        collection={collection}
        token={token}
        isOwner={checkOwner(account, token.owner)}
        onApproveOffer={onApproveOffer}
        tokenId={token.token_id}
      />
      {showApproveOfferDialog && (
        <ApproveOfferDialog
          token={token}
          collection={collection}
          setShowApproveOfferDialog={setShowApproveOfferDialog}
          allowAccessTokens={allowAccessTokens}
          open={showApproveOfferDialog}
          saleCompleted={saleCompleted}
        />
      )}
      {errorMetaMask && (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      )}
    </>
  );
};
interface OfferDetailsContainerProps {
  collection: CollectionInterface;
  token: Token;
}
export default OfferDetailsContainer;
