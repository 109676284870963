import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DialogActions, Divider } from "@mui/material";
import { handleImageCard, handleTokenName } from "../../../utils/util";
import "./cancelOfferDialog.scss";
import { convertWeiToETH } from "../../../utils/web3Utils";
import {Token} from "../../../interfaces/token";
import {CollectionInterface} from "../../../interfaces/collection";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const CancelOfferDialog: FC<CancelOfferDialogProps> = (
  props: CancelOfferDialogProps
) => {
  const { token, collection, offerPrice, open, setCancelOfferDialog } = props;

  const handleClose = () => {
    setCancelOfferDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Cancel Offer
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="listing-container">
            <div className="offer-name-details-wrapper">
              <div className="offer-nft-name-icon-wrapper">
                <div className="offer-nft-icon">
                  <img
                    src={handleImageCard(token)}
                    width="100"
                    height="100"
                    alt="card-img"
                  />
                </div>
                <div className="nft-name-collection-wrapper">
                  <div className="offer-nft-name">{handleTokenName(token)}</div>
                  <div className="offer-nft-collection">{collection.name}</div>
                </div>
              </div>
              <div className="listing-token-details">
                {offerPrice && (
                  <div className="offer-nft-price-eth">
                    offer price: {convertWeiToETH(offerPrice)}
                  </div>
                )}
              </div>
            </div>

            <Divider light className="divider" />
            <div className="offer-summary">
              <div className="text-linear-title-small">Note</div>
              <div className="const-text">
                Cancel Offer in progress, its will close when offer removed.
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface CancelOfferDialogProps {
  token: Token;
  collection: CollectionInterface;
  offerPrice: string;
  setCancelOfferDialog: Function;
  open: boolean;
}
export default CancelOfferDialog;
