import { useMetaMask } from "metamask-react";
import { FC, useEffect } from "react";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";
import { displayOwner, handleTokenName } from "../../../utils/util";
import { useNavigate } from "react-router-dom";
import "./tokenDetails.scss";

const TokenDetails: FC<TokenDetailsProps> = (props: TokenDetailsProps) => {
  const { token, collection, onRefreshToken } = props;
  const navigate = useNavigate();
  const { account } = useMetaMask();

  return (
    <div className="nft-name-buttons">
      <div className="nft-name-wrapper">
        <div
          className="title-and-refresh"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <a
            className="nft-collection-name"
            href={`/collection/${collection.collection_id}`}
          >
            {collection?.name}
          </a>
          <div
            title="refresh token"
            className="refresh-collection-wrapper"
            onClick={async () => await onRefreshToken(token)}
          >
            <div className="refresh-collection"></div>
            <img src="/refresh.svg" alt="" style={{ position: "absolute" }} />
          </div>
        </div>

        <div className="nft-name">{handleTokenName(token)}</div>
        <div className="nft-owner-wrapper">
          <div className="nft-owner">Owner:</div>
          <div
            className="nft-owner-address"
            onClick={() =>
              navigate(`/account/${displayOwner(account, token.owner)}`)
            }
          >
            {displayOwner(account, token.owner)}
          </div>
        </div>
      </div>
    </div>
  );
};
interface TokenDetailsProps {
  token: Token;
  collection: CollectionInterface;
  onRefreshToken: Function;
}
export default TokenDetails;


