import { FC, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, post } from "../../../fetch/fetchApi";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";
import RefreshCollectionDialog from "../../refreshCollectionDialog/refreshCollectionDialog";
import TokenDetails from "./tokenDetails";

const TokenDetailsContainer: FC<TokenDetailsContainerProps> = (
  props: TokenDetailsContainerProps
) => {
  const { token, collection } = props;
  const [showRefreshDialog, setShowRefreshDialog] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const refreshTokenCall = async (input: { token: Token }): Promise<any> => {
    const { token } = input;
    const response = await get(
      `tokens/v1/refresh/${collection.token_type}/${token.collection_id}/${token.token_id}`
    );
    return response;
  };

  const { mutate: mutateRefreshToken } = useMutation(refreshTokenCall, {
    onSuccess: (data, variables) => {
      setShowRefreshDialog(true);
      token.metadata = data;
      queryClient.invalidateQueries([
        "get-token-by-collection-id",
        variables.token.collection_id?.toLowerCase(),
        variables.token.token_id,
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onRefreshToken = async (token: Token) => {
    mutateRefreshToken({ token });
  };
  return (
    <>
      <TokenDetails
        token={token}
        collection={collection}
        onRefreshToken={onRefreshToken}
      />
      {showRefreshDialog && (
        <RefreshCollectionDialog
          isOpen={showRefreshDialog}
          setIsOpen={setShowRefreshDialog}
        />
      )}
    </>
  );
};
interface TokenDetailsContainerProps {
  token: Token;
  collection: CollectionInterface;
}
export default TokenDetailsContainer;
