import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";

export const getEthDollarValue = "eth-dolar";

export const useFetchEthUsdPrice = () => {
  return useQuery([getEthDollarValue], () => get(`v1/ethereum-usd-price`), {
    refetchInterval: 300000,
  });
};
