import Accordion from "@mui/material/Accordion";
import { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  BarChart,
  Bar,
  Line,
  ComposedChart,
} from "recharts";
import "./priceHistory.scss";
import EmptyState from "../../emptyState/emptyState";
import { convertWeiToETH } from "../../../utils/web3Utils";

const PriceHistory: FC<{
  priceHistory: [];
}> = ({ priceHistory }: { priceHistory: [] }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const convertData = (
    priceHistory: any[]
  ): { price: string; created_at: string }[] => {
    const result: { price: string; created_at: string }[] = [];
    priceHistory.forEach((element, index) => {
      // if (index === 0) {
      //   result.push({ created_at: "0", price: "0" });
      // }
      const createdAt = new Date(element.created_at);
      const formattedDate = `${createdAt.getDate()}-${
        createdAt.getMonth() + 1
      }-${createdAt.getFullYear().toString().substr(-2)}`;
      result.push({
        price: convertWeiToETH(element.price),
        created_at: formattedDate,
      });
    });

    return result;
  };

  const CustomTooltip: React.FC<{
    active?: boolean;
    payload?: any[];
    label?: string;
  }> = ({ active, payload, label }) => {
    if (active && payload && payload.length && payload[0].payload.price > 0) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${payload[0].value}`} ETH</p>
          <p className="intro">Avg. price: {`${payload[0].value}`} ETH</p>
          <p className="intro">Num. sales: 1</p>
          <p className="intro">{`${label}`}</p>
        </div>
      );
    }

    return null;
  };

  const data = [
    {
      created_at: "Oct' 21",
      price: 100,
    },
    {
      created_at: "Oct' 22",
      price: 200,
    },
  ];
  return (
    <Accordion
      className="nft-price-container"
      expanded={expanded}
      onChange={() => handleChange()}
    >
      <AccordionSummary
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
      >
        <img
          src="/priceHistory.svg"
          alt="Image"
          style={{ marginRight: "1rem" }}
        />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>
          Price history
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {priceHistory.length >= 1 ? (
          <div className="nft-price-history">
            <ResponsiveContainer height="100%" width="100%">
              <ComposedChart
                width={730}
                height={250}
                data={convertData(priceHistory)}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <XAxis dataKey="created_at" />
                <YAxis
                  label={{
                    value: "Volume (ETH)",
                    angle: -90,
                    position: "insideLeft",
                  }}
                />
                <Tooltip content={<CustomTooltip />} />
                <defs>
                  <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
                    <stop offset="5%" stopColor="#3762A6" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#D9329A" stopOpacity={0.8} />
                  </linearGradient>
                </defs>
                <Bar dataKey="price" fill="url(#colorUv)" maxBarSize={30} />
                <Line
                  type="monotone"
                  dataKey="price"
                  stroke="url(#colorUv)"
                  strokeWidth={3}
                  dot={true}
                  activeDot={{ r: 8 }}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <EmptyState textData={"No history yet"} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default PriceHistory;
