import { FC, useEffect, useState } from "react";
import { get } from "../../fetch/fetchApi";
import Explore from "../tokens/explore";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Token } from "../../interfaces/token";
import { CollectionInterface } from "../../interfaces/collection";
import { useGetTokensByCollectionId } from "../../queries/collections";

const TokensContainer: FC<TokensContainerProps> = (
  props: TokensContainerProps
) => {
  const { collection } = props;
  const [offset, setOffset] = useState<number>(0);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [searchText, setSearchText] = useState<string>("");

  const [value, setValue] = useState(0);
  const limit = 14;
  const total = collection.total_tokens;

  const { data: tokensData, isFetching } = useGetTokensByCollectionId(
    collection,
    offset,
    searchText
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onLoadMore = () => {
    setOffset(offset + limit + 1);
  };
  useEffect(() => {
    setTokens([]);
  }, [collection.collection_id]);

  useEffect(() => {
    if (tokensData) {
      const getMetadataByToken = async () => {
        const promises = tokensData.map((token: any) => {
          return get(
            `tokens/v1/metadata/${token.collection_id}/${token.token_id}?full_size=false`
          );
        });

        const metadata = await Promise.all(promises);
        tokensData.forEach((token: any, i: number) => {
          token.metadata = metadata[i];
        });

        setTokens([...tokens, ...tokensData]);
      };
      getMetadataByToken();
    }
  }, [tokensData]);

  return (
    <div className="container">
      <Tabs className="tabs" value={value} onChange={handleChange}>
        <Tab label="Items" />
        {/* <Tab label="Activity" /> */}
      </Tabs>
      {value === 0 && (
        <Explore
          hasMore={offset + limit < total}
          onLoadMore={onLoadMore}
          isLoading={isFetching}
          tokens={tokens}
          collection={collection}
          setTokens={setTokens}
          setSearchText={setSearchText}
        />
      )}
      {/* {value === 1 && (
        <Explore
          isLoading={isLoading}
          tokens={tokens}
          collection={collection}
          setTokens={setTokens}
        />
      )} */}
    </div>
  );
};

interface TokensContainerProps {
  collection: CollectionInterface;
}
export default TokensContainer;
