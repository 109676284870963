import React, { FC, useState } from "react";
import "./collectionsStats.scss";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { CollectionsStatsTable } from "./table/collectionsStatsTable";
import { useQuery, UseQueryResult } from "react-query";
import { TimePicker } from "./timePicker/timePicker";
import { CollectionStats } from "../../interfaces/collection";

enum CollectionsStatsTab {
  Top,
  Watchlist,
}

const getTopCollections = async (
  offset: number
): Promise<CollectionStats[]> => {
  return Promise.resolve([
    {
      id: 1,
      name: "test",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 2,
      name: "test",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 3,
      name: "test",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 4,
      name: "test",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 5,
      name: "test",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
  ]);
};

const getWatchedCollections = async (
  offset: number
): Promise<CollectionStats[]> => {
  return Promise.resolve([
    {
      id: 1,
      name: "This is a very long collection name for testing a very long collection",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 2,
      name: "watched",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 3,
      name: "watched",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 4,
      name: "watched",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
    {
      id: 5,
      name: "watched",
      volume: 1,
      floor_price: 0.5,
      sales: 4,
      unique_owners: 3,
      items_listed: 5,
    },
  ]);
};

const CollectionsStats: FC<{}> = () => {
  const [tab, setValue] = useState(CollectionsStatsTab.Top);
  const [timePicked, setTimePicked] = useState("24H");
  const handleTabChanged = (event: React.SyntheticEvent, newTab: number) => {
    setValue(newTab);
  };
  const useFetchTopCollectionsQuery = (
    offset: number,
    timePicked: string
  ): UseQueryResult<Array<CollectionStats>, Error> => {
    return useQuery(["fetchTopCollectionsQuery", offset, timePicked], () =>
      getTopCollections(offset)
    );
  };
  const useFetchWatchedCollectionsQuery = (
    offset: number,
    timePicked: string
  ): UseQueryResult<Array<CollectionStats>, Error> => {
    return useQuery(["fetchWatchedCollectionsQuery", offset, timePicked], () =>
      getWatchedCollections(offset)
    );
  };

  return (
    <>
      <div className="container-collections-stats">
        <Tabs value={tab} onChange={handleTabChanged}>
          <Tab label="Top" />
          <Tab label="Watchlist" />
        </Tabs>
        <div>
          <TimePicker setTimePicked={setTimePicked}></TimePicker>
        </div>
        <p></p>
        {tab === CollectionsStatsTab.Top && (
          <CollectionsStatsTable
            useFetchQuery={useFetchTopCollectionsQuery}
            timePicked={timePicked}
          ></CollectionsStatsTable>
        )}
        {tab === CollectionsStatsTab.Watchlist && (
          <CollectionsStatsTable
            useFetchQuery={useFetchWatchedCollectionsQuery}
            timePicked={timePicked}
          ></CollectionsStatsTable>
        )}
      </div>
    </>
  );
};

export default CollectionsStats;
