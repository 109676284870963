import { FC } from "react";

const MarkCheckBoxIcon: FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="20"
        height="20"
        rx="4"
        fill="url(#paint0_linear_2204_15398)"
      />
      <path
        d="M15.3346 6L8.0013 13.3333L4.66797 10"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <rect
        x="0.5"
        y="0.5"
        width="19"
        height="19"
        rx="3.5"
        stroke="url(#paint1_linear_2204_15398)"
        stroke-opacity="0.6"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2204_15398"
          x1="-5.96046e-07"
          y1="20"
          x2="21.9512"
          y2="17.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3762A6" />
          <stop offset="1" stop-color="#D9329A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2204_15398"
          x1="-5.96046e-07"
          y1="20"
          x2="21.9512"
          y2="17.561"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#3762A6" />
          <stop offset="1" stop-color="#D9329A" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MarkCheckBoxIcon;
