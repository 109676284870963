import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";

export const profile = "profile";

export const useGetCollectionsByAddress = (
  account: string | undefined,
  wallet: string | undefined,
  offset: number
) => {
  let keyword = wallet ? wallet : account;
  return useQuery(
    [profile, keyword, offset],
    () => get(`tokens/v1/owner/${keyword}/${offset}`),
    {
      enabled: !!keyword,
    }
  );
};

export const useGetCountCollectionsByAddress = (
  account: string | undefined,
  wallet: string | undefined
) => {
  let address = wallet ? wallet : account;
  return useQuery(
    [profile, address],
    () => get(`tokens/v1/owner/count/${address}`),
    {
      enabled: !!address,
    }
  );
};
