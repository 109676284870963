import { FC, useEffect, useState } from "react";
import { Token } from "../../../interfaces/token";
import { useGetTokenPrice } from "../../../queries/tokenDetailsQueries";
import PriceHistory from "./priceHistory";

const HistoryPriceContainer: FC<{
  token: Token;
}> = ({ token }: { token: Token }) => {
  const [priceHistory, setPriceHistory] = useState<[]>([]);

  const { data } = useGetTokenPrice(token);

  useEffect(() => {
    if (!data) return;

    setPriceHistory(data);
  }, [data]);

  return <PriceHistory priceHistory={priceHistory} />;
};
export default HistoryPriceContainer;
