import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell, Tooltip } from "@mui/material";
import { FC } from "react";
import Button from "@mui/material/Button";
import Loader from "../loader/loader";
import { useFetchEthUsdPrice } from "../../queries/ethDollar";
import { displayLeftAuctionTime } from "../../utils/util";
import { ListingTable } from "../../interfaces/ListingTypes";
import EmptyState from "../emptyState/emptyState";
import { convertWeiToETH } from "../../utils/web3Utils";

export const ListingTableComponent: FC<ListingTableProps> = (
  props: ListingTableProps
) => {
  const { isLoading, listings } = props;
  const { data: ethUsdPrice } = useFetchEthUsdPrice();

  const onShowMoreClicked = () => {};

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <TableContainer component={Paper} sx={{ background: "transparent" }}>
        <Table stickyHeader aria-label="collections stats">
          {listings && listings.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>COLLECTION</TableCell>
                  <TableCell>SALES</TableCell>
                  <TableCell>FlOOR PRICE</TableCell>
                  <TableCell>Your offer</TableCell>
                  <TableCell>Expiration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listings.map((listing, i) => (
                  <TableRow key={i}>
                    <TableCell className="collection-image-cell">
                      {listing.metadata ? (
                        <>
                          <img
                            className="collection-image"
                            src={
                              listing.metadata.image
                                ? listing.metadata.image
                                : "/noContent.svg"
                            }
                            alt=""
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div>{listing.metadata.name}</div>
                            <div
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              {listing.collection_name}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>{listing.token_id}</div>
                      )}

                      <Tooltip title={listing.collection_name} placement="top">
                        <div></div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{listing.sales ? listing.sales : 0}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div> {convertWeiToETH(listing?.floor_price)}</div>
                        <img src="/eth.svg" alt="" />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{listing.price} ETH</div>
                      <div className="grey-font-usd">
                        {(
                          parseFloat(listing.price) * parseInt(ethUsdPrice)
                        ).toFixed(2) + "$"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="grey-font-usd">
                        {displayLeftAuctionTime(
                          listing.start_time,
                          listing.end_time
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <EmptyState textData={"No listing yet"} />
          )}
        </Table>
      </TableContainer>
      {/* <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button onClick={onShowMoreClicked} className="show-more-gradient-btn">
          Show More
        </Button>
      </div> */}
    </>
  );
};
interface ListingTableProps {
  listings: ListingTable[];
  isLoading: boolean;
}
