import { FC, useState } from "react";
import { Action, ActionType } from "../../interfaces/nftActions";
import { useNavigate } from "react-router-dom";
import { CollectionInterface } from "../../interfaces/collection";
import CollectionCard from "../collectionCard/collectionCard";
import TokenCardSkeleton from "../tokenCard/tokenCardSkeletor";
import "./myCollections.scss";

const MyCollections: FC<MyCollectionsInterface> = (
  props: MyCollectionsInterface
) => {
  const { myCollections, isLoading, isError } = props;
  const navigate = useNavigate();

  const actions: Action[] = [
    {
      title: "Edit",
      type: ActionType.List,
    },
    {
      title: "Transfer",
      type: ActionType.Transfer,
    },
  ];

  const handleClick = (collection: CollectionInterface) => {
    navigate(`/collection/${collection.collection_id}`);
  };

  return (
    <div className="collections-list">
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {isLoading ? (
          <TokenCardSkeleton totalTokens={5} />
        ) : (
          myCollections?.map((collection: CollectionInterface, i) => (
            <CollectionCard
              collection={collection}
              key={i}
              onClick={() => handleClick(collection)}
            />
          ))
        )}
      </div>
    </div>
  );
};
interface MyCollectionsInterface {
  myCollections: CollectionInterface[];
  isLoading: boolean;
  isError: boolean;
}

export default MyCollections;
