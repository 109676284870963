import { FC, useEffect, useState } from "react";
import { useMetaMask } from "metamask-react";
import ConnectWallet from "./connectWallet";
import User from "../user/user";
import { useQuery } from "react-query";
import { get } from "../../fetch/fetchApi";
import { Token } from "../../interfaces/token";
import { useParams } from "react-router-dom";
import {
  useGetCollectionsByAddress,
  useGetCountCollectionsByAddress,
} from "../../queries/owner";

const AccountContainer: FC = () => {
  const { status, connect, account } = useMetaMask();
  const { walletAddress } = useParams();
  const [offset, setOffset] = useState<number>(0);
  const [tokens, setTokens] = useState<Token[]>([]);
  const [showWalletDialog, setShowWalletDialog] = useState<boolean>(false);
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [tokensCount, setTokensCount] = useState<number>();
  const { data: tokensData, isLoading } = useGetCollectionsByAddress(
    account || undefined,
    walletAddress,
    offset
  );

  const { data: tokensCountData, isLoading: isLoadingTokensCount } =
    useGetCountCollectionsByAddress(account || undefined, walletAddress);

  useEffect(() => {
    if (!tokensCountData) return;
    setTokensCount(tokensCountData["count"]);
  }, [tokensCount, isLoadingTokensCount]);

  const limit = 14;

  const getAccount = () => {
    if (walletAddress) {
      return walletAddress;
    }
    if (account) return account;
  };

  const onLoadMore = () => {
    setOffset(offset + limit + 1);
  };
  useEffect(() => {
    if (tokensData) {
      const getMetadataByToken = async () => {
        const promises = tokensData.map((token: any) => {
          return get(
            `tokens/v1/metadata/${token.collection_id}/${token.token_id}?full_size=false`
          );
        });

        const metadata = await Promise.all(promises);
        tokensData.forEach((token: any, i: number) => {
          token.metadata = metadata[i];
        });

        setTokens([...tokens, ...tokensData]);
        setIsLoadingData(false);
      };
      getMetadataByToken();
    }
  }, [tokensData]);

  return (
    <div className="container">
      {walletAddress == "undefined" &&
      (status === "notConnected" || status === "unavailable") ? (
        <ConnectWallet
          connect={connect}
          setShowWalletDialog={setShowWalletDialog}
          open={showWalletDialog}
        />
      ) : (
        tokensCount && (
          <User
            hasMore={offset + limit <= tokensCount}
            onLoadMore={onLoadMore}
            tokens={tokens}
            isLoading={isLoading}
            isLoadingData={isLoadingData}
            account={getAccount()!}
          />
        )
      )}
    </div>
  );
};
export default AccountContainer;
