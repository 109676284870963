import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../fetch/fetchApi";
import { OffersTable } from "./offersTable";
import { OfferTable } from "../../interfaces/OfferTypes";
import { convertWeiToETH } from "../../utils/web3Utils";

const OffersContainer: FC<OffersProps> = (props: OffersProps) => {
  const { account } = props;
  const [offers, setOffers] = useState<OfferTable[]>([]);
  const { data, isLoading } = useQuery(
    ["get-offers", `${account}`],
    () => get(`get-offers?wallet_address=${account}`),
    {
      enabled: !!account,
    }
  );
  useEffect(() => {
    if (data) {
      const tempOffers: OfferTable[] = [];
      data.map((item: any) => {
        tempOffers.push({
          token_id: item.token_id,
          image_url: item.image_url,
          price: convertWeiToETH(item.price),
          start_time: item.start_time,
          collection_name: item.name,
          end_time: item.end_time,
          sales: item.sales,
          metadata: item.metadata,
          collection_id: item.collection_id,
        });
      });
      setOffers(tempOffers);
    }
  }, [data]);

  return <OffersTable offers={offers} isLoading={isLoading} />;
};
interface OffersProps {
  account: string;
}
export default OffersContainer;
