import Accordion from "@mui/material/Accordion";
import { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import EmptyState from "../../emptyState/emptyState";
import { Token } from "../../../interfaces/token";
import "./properties.scss";

const Properties: FC<PropertiesProps> = (props: PropertiesProps) => {
  const { token } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="item-activity-container"
      expanded={expanded}
      onChange={() => handleChange()}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
      >
        <img src="/properties.svg" alt="" style={{ marginRight: "1rem" }} />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>
          Propperties
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontFamily: "LemonMilk-light",
          fontSize: "14px",
          padding: "0",
          marginTop: "0.5rem",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {token.metadata && token.metadata.attributes.length > 1 ? (
          token.metadata.attributes.map((rarity: any, i) => (
            <div className="properties-box">
              <div className="property-trait">{rarity?.trait_type}</div>
              <div className="property-rarity">{rarity.value}</div>
              {/* <div className="property-count">
                {handlePrecentages(rarity.rarity_counts[0].count)}
              </div> */}
            </div>
          ))
        ) : (
          <EmptyState textData={"traits not found"} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
export interface PropertiesProps {
  token: Token;
}
export default Properties;
