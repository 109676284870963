import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";
import { Token } from "../interfaces/token";

export const getLatestListingNonce = "latest-listing-nonce";
export const getLatestOfferNonce = "latest-offer-nonce";

export const useGetLatestListingNonce = (account: string, token: Token) => {
  return useQuery(
    [
      getLatestListingNonce,
      `${account}/${token?.collection_id}/${token?.token_id}`,
    ],
    () => get(`tokens/v1/latest-listing-nonce?seller=${account}`),
    {
      enabled: !!(account && token),
    }
  );
};

export const useGetLatestOfferNonce = (account: string, token: Token) => {
  return useQuery(
    [
      getLatestOfferNonce,
      `${account}/${token?.collection_id}/${token?.token_id}`,
    ],
    () => get(`tokens/v1/latest-offer-nonce?buyer=${account}`),
    {
      enabled: !!(account && token),
    }
  );
};
