import { useMetaMask } from "metamask-react";
import { FC, useEffect, useState } from "react";
import Web3 from "web3";
import {
  getUserBalanceWETH,
  getUserBalanceETH,
  depositWETH,
} from "../../../utils/web3Utils";
import SwapETHDialog from "./swapEthWethDialog";

const SwapContainer: FC<SwapContainerProps> = (props: SwapContainerProps) => {
  const { setShowDwapDialog, showDialog } = props;
  const [userBalanceWETH, setUserBalanceWETH] = useState<string>("");
  const [userBalanceETH, setUserBalanceETH] = useState<string>("");
  const [loader, setLoader] = useState<boolean>(false);
  const { account, chainId, switchChain } = useMetaMask();

  useEffect(() => {
    if (!account) return;

    getUserBalanceWETH(account).then((data: string) => {
      setUserBalanceWETH(data.slice(0, 5));
    });
    getUserBalanceETH(account).then((data: string) => {
      setUserBalanceETH(data.slice(0, 5));
    });
  }, []);

  const convert = async (eth: string, weth: string) => {
    setLoader(true);
    if (chainId !== process.env.REACT_APP_CHAIN_ID_PROD) {
      await switchChain("0x1");
      return;
    }

    if (account) {
      //const approve = await setApproveWETH(account, 1);
      const deposit = await depositWETH(account, Web3.utils.toWei(eth));
      if (deposit) {
        setLoader(false);
        const newBalance = userBalanceWETH + eth;
        setUserBalanceWETH(newBalance);
        //render success message
        //update weth balance
      }
    }
  };

  return (
    <SwapETHDialog
      setShowSwapDialog={setShowDwapDialog}
      showDialog={showDialog}
      userBalanceWETH={userBalanceWETH}
      userBalanceETH={userBalanceETH}
      handleConvert={convert}
      loader={loader}
    />
  );
};
interface SwapContainerProps {
  setShowDwapDialog: Function;
  showDialog: boolean;
}
export default SwapContainer;
