import { FC, useEffect, useState } from "react";
import Description from "./description/description";
import {
  checkOwner,
  handleImageCard,
  handleImageCardOverlay,
} from "../../utils/util";
import Details from "./details/details";
import Properties from "./properties/properties";
import { useMetaMask } from "metamask-react";
import ItemActivityContainer from "./itemActivity/itemActivityContainer";
import { Token } from "../../interfaces/token";
import "./tokenContainer/singleNft.scss";
import TokenActionsContainer from "../tokensActions/tokenActionsContainer.tsx/tokenActionsContainer";
import OfferDetailsContainer from "../tokensActions/OfferDetailsContainer/offerDetailsContainer";
import ListingComponent from "./listing/listing";
import { CollectionInterface } from "../../interfaces/collection";
import HistoryPriceContainer from "./historyPrice/historyPriceContainer";
import TokenDetailsContainer from "./tokenDetails/tokenDetailsContainer";
import { Container, Grid, Paper } from "@mui/material";

const TokenDetailsPage: FC<TokenDetailsPageProps> = (
  props: TokenDetailsPageProps
) => {
  const { token, collection } = props;
  const { account } = useMetaMask();
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(false);

  useEffect(() => {
    if (token.metadata) {
      setIsImage(token.metadata.image.includes("/image/upload/"));
      setIsVideo(token.metadata.image.includes("/video/upload/"));
    }
  }, []);

  return (
    <div className="nft-container">
      <div className="nft-right-left-wrapper">
        <div className="nft-left-side">
          <Paper
            sx={{
              background:
                token.metadata === null
                  ? "rgba(255, 255, 255, 0.15)"
                  : "transparent",
              minHeight: "10rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ position: "relative", display: "inline-block" }}>
              {isImage && (
                <>
                  <div className="image-overly"></div>
                  <img
                    src={handleImageCard(token, collection)}
                    alt=""
                    className="nft-image"
                    style={{ maxWidth: "100%", height: "auto" }}
                  />
                </>
              )}
              {isVideo && (
                <video
                  controls
                  style={{
                    borderRadius: "32px",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                >
                  <source src={token.metadata?.image} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              )}

              {!handleImageCardOverlay(token) && (
                <div
                  className="hovered-token-details"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: "rgba(255, 255, 255, 0.15)",

                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 100,
                  }}
                >
                  {" "}
                  <p
                    style={{
                      color: "black",
                      fontWeight: "bold",
                      fontSize: "24px",
                    }}
                  >
                    Content is not available
                  </p>
                </div>
              )}
            </div>
          </Paper>

          <Description description={collection?.description} />
          <Properties token={token} />
        </div>
        <div className="nft-right-side">
          <TokenDetailsContainer token={token} collection={collection} />
          <TokenActionsContainer token={token} collection={collection} />
          <HistoryPriceContainer token={token} />
          <ListingComponent
            listing={token.listing}
            isOwner={checkOwner(account, token.owner)}
          />
          <OfferDetailsContainer collection={collection} token={token} />
          <Details collection={collection} token={token} />
        </div>
      </div>

      <div className="nft-bottom">
        <ItemActivityContainer token={token} />
        {/* <MoreFromThisCollection /> */}
      </div>
    </div>
  );
};

interface TokenDetailsPageProps {
  token: Token;
  collection: CollectionInterface;
}

export default TokenDetailsPage;
