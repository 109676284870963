import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./listingTokenDialog.scss";
import { CollectionInterface } from "../../../interfaces/collection";
import {
  Button,
  DialogActions,
  InputBase,
  InputAdornment,
  Divider,
  FormHelperText,
} from "@mui/material";
import { useMetaMask } from "metamask-react";
import {
  convertETHtoWei,
  convertWeiToETH,
  getUserBalanceETH,
} from "../../../utils/web3Utils";
import CustomDurationPicker from "../../duration/duration";
import dayjs, { Dayjs } from "dayjs";
import { handleImageCard, handleTokenName } from "../../../utils/util";
import { Token } from "../../../interfaces/token";
import { useFetchEthUsdPrice } from "../../../queries/ethDollar";
import { addressesByNetwork } from "../../../constants/addresses";
import { Listing } from "../../../interfaces/ListingTypes";
import { encodeParams } from "../../../utils/signMessagesUtil";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ListingTokenDialog: FC<ListingTokenDialogProps> = (
  props: ListingTokenDialogProps
) => {
  const {
    token,
    setShowMakeOfferDialog,
    open,
    collection,
    listingAction,
    nonce,
  } = props;
  const [wethPrice, setWethPrice] = useState<number>(0);
  const { account, chainId } = useMetaMask();
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(1, "day")); // set initial value to tomorrow's date
  const [error, setError] = useState(false);
  const { data: ethUsdPrice } = useFetchEthUsdPrice();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const handleInputChange = (event: any) => {
    const newValue = Number(event.target.value);

    if (0 < newValue) {
      setError(false);
      setDisableButton(false);
    } else {
      setError(true);
      setDisableButton(true);
    }
    setWethPrice(event.target.value);
  };

  const handleClose = () => {
    setShowMakeOfferDialog(false);
  };

  const handleOnListingToken = async (
    seller: string | null,
    collectionId: string,
    tokenId: number,
    price: string,
    endTime: number
  ) => {
    if ((price && price <= "0") || !seller || !chainId) {
      return;
    }
    const date = new Date(endTime);
    const utcDate = new Date(date.toUTCString());

    const listing: Listing = {
      seller: seller,
      collection: collectionId,
      tokenId: tokenId,
      price: price,
      amount: 1,
      strategy: addressesByNetwork(chainId).STRATEGY_STANDARD_SALE_SINGLE_ITEM,
      currency: addressesByNetwork(chainId).WETH,
      nonce: nonce,
      startTime: Math.round(new Date().getTime() / 1000),
      endTime: Math.round(utcDate.getTime() / 1000),
      params: encodeParams([]),
      signature: "",
    };
    await listingAction(listing);

    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Listing token
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="listing-container">
            <div className="offer-name-details-wrapper">
              <div className="offer-nft-name-icon-wrapper">
                <div className="offer-nft-icon">
                  <img
                    src={handleImageCard(token)}
                    width="100"
                    height="100"
                    alt="card-img"
                  />
                </div>
                <div className="nft-name-collection-wrapper">
                  <div className="offer-nft-name">{handleTokenName(token)}</div>
                  <div className="offer-nft-collection">{collection.name}</div>
                </div>
              </div>
              <div className="listing-token-details">
                <div className="offer-nft-price-eth">
                  floor price: {convertWeiToETH(collection.floor_price)}
                </div>
                <div className="offer-nft-price-eth">
                  best offer:{" "}
                  {convertWeiToETH(collection.best_offer.toString())}
                </div>
              </div>
            </div>

            <div className="listing-set-price">
              <div className="duration-const">Set Price</div>
              <div className="offer-price">
                <InputBase
                  sx={{
                    width: "100%",
                    fontFamily: "LemonMilk",
                    marginLeft: "1rem",
                  }}
                  placeholder="Price"
                  value={wethPrice}
                  error={error}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment
                      sx={{ color: "white", marginRight: "1.5rem" }}
                      position="end"
                    >
                      <div>ETH</div>
                    </InputAdornment>
                  }
                />
              </div>
              {error && (
                <FormHelperText error>
                  Price should be higher then 0.
                </FormHelperText>
              )}
            </div>

            <div className="offer-duration">
              <div className="duration-const">Set Duration</div>
              <div className="duration-value">
                <CustomDurationPicker setDate={setEndDate} date={endDate} />
              </div>
            </div>
            <Divider light className="divider" />
            <div className="listing-summary">
              <div className="duration-const">SUMMARY</div>
              <div className="const-text">Fees: 0 </div>{" "}
              <div className="const-text">
                Possible earn: {wethPrice} /
                {(parseInt(ethUsdPrice) * wethPrice).toFixed(2)}$
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={disableButton ? "gradient-btn-disabled" : "gradient-btn"}
            autoFocus
            disabled={disableButton}
            sx={{ marginBottom: "1rem" }}
            onClick={async () =>
              await handleOnListingToken(
                account,
                token.collection_id,
                token.token_id,
                convertETHtoWei(wethPrice.toString()),
                endDate.valueOf()
              )
            }
          >
            List now
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface ListingTokenDialogProps {
  token: Token;
  setShowMakeOfferDialog: Function;
  listingAction: Function;
  open: boolean;
  collection: CollectionInterface;
  nonce: number;
}
export default ListingTokenDialog;
