import Accordion from "@mui/material/Accordion";
import React, { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell } from "@mui/material";
import {
  ItemActivityUI,
  ItemActivityTypes,
} from "../../../interfaces/itemActivity";
import EmptyState from "../../emptyState/emptyState";
import { calPastDate } from "../../../utils/util";
import "./itemActivity.scss";
import { convertWeiToETH } from "../../../utils/web3Utils";

const ItemActivity: FC<ItemActivityProps> = (props: ItemActivityProps) => {
  const { itemActivity, isLoading } = props;
  const [expanded, setExpanded] = useState(false);

  const headers = ["EVENT", "PRICE", "FROM", "TO", "DATE"];

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const renderIcon = (activity: ItemActivityTypes) => {
    let iconType;
    switch (activity) {
      case ItemActivityTypes.Trasnfer:
        iconType = "/transfer.svg";
        break;
      case ItemActivityTypes.Listing:
        iconType = "/listing.svg";
        break;
      case ItemActivityTypes.Sale:
        iconType = "/sale.svg";
        break;
      case ItemActivityTypes.Offer:
        iconType = "/offer.svg";
        break;
      default:
        break;
    }
    return (
      <img
        src={iconType}
        alt=""
        style={{
          width: "16px",
          height: "16px",
          marginRight: "0.5rem",
        }}
      ></img>
    );
  };

  return (
    <Accordion
      className="item-activity-container"
      expanded={expanded}
      onChange={() => handleChange()}
      square
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
      >
        <img
          src="/activity.svg"
          alt="activity"
          style={{ marginRight: "1rem" }}
        />
        <Typography>ITEM ACTIVITY</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontFamily: "LemonMilk-light",
          fontSize: "14px",
          padding: "0",
          marginTop: "0.5rem",
        }}
      >
        <TableContainer component={Paper} sx={{ background: "transparent" }}>
          <Table stickyHeader aria-label="collections stats">
            {itemActivity.length > 0 ? (
              <>
                {" "}
                <TableHead>
                  <TableRow>
                    {headers.map((header: string, i) => (
                      <TableCell key={i}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {itemActivity?.map((item, i) => (
                    <TableRow key={i}>
                      <TableCell className="item-activity-event">
                        {renderIcon(item.event)}
                        {item.event}
                      </TableCell>
                      <TableCell className="item-activity-price-wrapper">
                        <div className="icon-price">
                          <img src="/smallEth.svg" alt="" />
                          <div className="item-activity-price">
                            {convertWeiToETH(item.price!)}
                          </div>
                        </div>
                        <div className="item-activity-usd">
                          {item.priceUsd ? parseInt(item.priceUsd) + "$" : ""}
                        </div>
                      </TableCell>
                      <TableCell>{item.from.slice(0, 10) + "..."}</TableCell>
                      <TableCell>{item.to.slice(0, 10) + "..."}</TableCell>

                      <TableCell>{calPastDate(item.date.toString())}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <EmptyState textData={"No item activity yet"} />
            )}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};
export interface ItemActivityProps {
  itemActivity: ItemActivityUI[];
  isLoading: boolean;
}
export default ItemActivity;
