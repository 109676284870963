import { useMetaMask } from "metamask-react";
import { FC, useEffect, useState } from "react";
import { CollectionInterface } from "../../interfaces/collection";
import { useGetMyCollections } from "../../queries/collections";
import MyCollections from "./myCollections";

const MyCollectionsContainer: FC = () => {
  const [collections, setCollections] = useState<CollectionInterface[]>([]);
  const { account } = useMetaMask();
  const { data, isLoading, isError } = useGetMyCollections(account!);

  useEffect(() => {
    if (data) {
      setCollections(data);
    }
  }, [data]);

  return (
    <MyCollections
      myCollections={collections}
      isLoading={isLoading}
      isError={isError}
    />
  );
};

export default MyCollectionsContainer;
