import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";
import { CollectionInterface } from "../interfaces/collection";

export const search = "search";
export const myCollections = "search";

export const useSearchCollection = (keyword: string) => {
  return useQuery([search], () => get(`collections/v1/name?name=${keyword}`), {
    enabled: !!keyword,
  });
};

export const useGetMyCollections = (owner: string) => {
  return useQuery(
    [myCollections],
    () => get(`collection/v1/${owner.toLowerCase()}`),
    {
      enabled: !!owner,
    }
  );
};

export const useGetTokensByCollectionId = (
  collection: CollectionInterface,
  offset: number,
  searchText: string
) => {
  return useQuery(
    ["tokens-per-collection", collection.collection_id, offset],
    () => get(`collection/v1/tokens/${collection.collection_id}/${offset}`),
    {
      enabled: searchText === "",
    }
  );
};

export const useIsWatchedByWallet = (
  collection: CollectionInterface,
  wallet: string
) => {
  return useQuery(
    ["is-watched-by", collection.collection_id, wallet],
    () => get(`collections/v1/${collection.collection_id}/${wallet}`),
    {
      enabled: !!wallet,
    }
  );
};
