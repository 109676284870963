import { useState, useEffect, FC } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import "./search.scss";
import { useSearchTokens } from "../../queries/tokens";
import { CollectionInterface } from "../../interfaces/collection";
import { get } from "../../fetch/fetchApi";

const SearchToken: FC<SearchTokenProps> = (props: SearchTokenProps) => {
  const { setTokens, collection, setSearchText } = props;
  const [searchQuery, setSearchQuery] = useState("");

  const { isLoading, data } = useSearchTokens(
    searchQuery,
    collection.collection_id
  );

  const handleSearch = (event: any) => {
    event.preventDefault();
  };

  const handleInputChange = (event: any) => {
    setSearchQuery(event.target.value);
    setSearchText(event.target.value);
  };

  useEffect(() => {
    if (data) {
      const getMetadataByToken = async () => {
        const promises = data.map((token: any) => {
          return get(
            `tokens/v1/metadata/${token.collection_id}/${token.token_id}?full_size=false`
          );
        });

        const metadata = await Promise.all(promises);
        data.forEach((token: any, i: number) => {
          token.metadata = metadata[i];
        });

        setTokens(data);
      };
      getMetadataByToken();
    }
  }, [data]);

  return (
    <form onSubmit={handleSearch}>
      <TextField
        type="number"
        sx={{
          color: "white ",
          fontFamily: "LemonMilk !important",
          width: "20rem",
        }}
        placeholder="Search for token"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <IconButton type="submit" aria-label="search">
                <SearchIcon sx={{ color: "white " }} />
              </IconButton>
            </InputAdornment>
          ),
        }}
        value={searchQuery}
        onChange={handleInputChange}
      />
    </form>
  );
};
interface SearchTokenProps {
  setTokens: Function;
  collection: CollectionInterface;
  setSearchText: Function;
}
export default SearchToken;
