import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import RefreshCollectionDialog from "../refreshCollectionDialog/refreshCollectionDialog";
import CollectionHeaderContainer from "../collectionHeader/collectionHeaderContainer";
import TokensContainer from "../tokensContainer/tokensContainer";
import { get } from "../../fetch/fetchApi";
import { useQuery } from "react-query";
import { CollectionInterface } from "../../interfaces/collection";
import "./collectionContainer.scss";
import Loader from "../loader/loader";

const CollectionContainer: FC = () => {
  const { id } = useParams();
  const [showRefreshDialog, setShowRefreshDialog] = useState<boolean>(false);
  const [collection, setCollection] = useState<CollectionInterface>();

  const { data: dataHeaders, isLoading: isLoadingHeaders } = useQuery(
    ["collections", id],
    () => get(`collection/v1/header/${id?.toLowerCase()}`)
  );

  useEffect(() => {
    if (dataHeaders) {
      setCollection(dataHeaders);
    }
  }, [dataHeaders, isLoadingHeaders, id]);

  return (
    <>
      {collection ? (
        <>
          <CollectionHeaderContainer
            collectionHeader={collection}
            setShowRefreshDialog={setShowRefreshDialog}
          />
          <TokensContainer collection={collection} />
        </>
      ) : (
        <div style={{ height: "20rem" }}>
          <Loader />
        </div>
      )}

      <RefreshCollectionDialog
        isOpen={showRefreshDialog}
        setIsOpen={setShowRefreshDialog}
      />
    </>
  );
};

export default CollectionContainer;
