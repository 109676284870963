import { Button } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../../fetch/fetchApi";
import GlobalDialog from "../../globalDialog/globalDialog";
import { CollectionInterface } from "../../../interfaces/collection";
import {
  ItemActivityRequest,
  ItemActivityTypes,
} from "../../../interfaces/itemActivity";
import { Token } from "../../../interfaces/token";
import ListingTokenDialog from "../listingTokenDialog/listingTokenDialog";
import { useGetLatestListingNonce } from "../../../queries/nonce";
import { useMetaMask } from "metamask-react";
import { Listing } from "../../../interfaces/ListingTypes";
import "./listingTokenContainer.scss";
import { getListingMsg } from "../../../utils/signMessagesUtil";
import {
  isApproveERC1155Collection,
  isApproveERC721Collection,
  setApproveERC1155Collection,
  setApproveERC721Collection,
} from "../../../utils/web3Utils";
import ListingTokenProgress from "../listingTokenProgressDialog/listingTokenProgressDialog";
import { TokenType } from "../../../interfaces/tokenTypeEnum";

const ListingTokenContainer: FC<ListingTokenContainerProps> = (
  props: ListingTokenContainerProps
) => {
  const { token, collection } = props;
  const queryClient = useQueryClient();
  const { account, chainId, switchChain } = useMetaMask();
  const [showListingDialog, setShowListingDialog] = useState<boolean>(false);
  const [showListingProgressDialog, setShowListingProgressDialong] =
    useState<boolean>(false);
  const [signListing, setSignListing] = useState<boolean>(false);
  const [listCompleted, setListCompleted] = useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);
  const [listing, setListing] = useState<Listing>();

  const { data: dataLatestListingNonce } = useGetLatestListingNonce(
    account || "",
    token
  );

  const addItemActivity = async (input: {
    itemActivity: ItemActivityRequest;
  }): Promise<any> => {
    const { itemActivity } = input;
    const response = await post("tokens/v1/activity", {
      collection_id: itemActivity.collectionId,
      token_id: itemActivity.tokenId,
      activity_type: itemActivity.event,
      from_address: itemActivity.from,
      to_address: itemActivity.to,
      price: itemActivity.price,
    });
    return response;
  };

  const { mutate: mutateAddItemActivity } = useMutation(addItemActivity, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "get-token-activity",
        `${variables.itemActivity.collectionId}/${variables.itemActivity.tokenId}`,
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const sendListingRequestWithSigned = async (input: {
    listing: Listing;
  }): Promise<any> => {
    const { listing } = input;
    return await post(`tokens/v1/add-listing`, {
      seller: listing.seller,
      collection_id: listing.collection,
      token_id: listing.tokenId,
      price: listing.price,
      amount: listing.amount,
      strategy: listing.strategy,
      currency: listing.currency,
      nonce: listing.nonce,
      start_time: listing.startTime,
      end_time: listing.endTime,
      params: listing.params,
      signature: listing.signature,
    });
  };

  const { mutate: mutateListingRequest } = useMutation(
    sendListingRequestWithSigned,
    {
      onSuccess: (data, variables, context) => {
        queryClient.invalidateQueries([
          "get-token-by-collection-id",
          variables.listing.collection.toLowerCase(),
          variables.listing.tokenId,
        ]);
        setListCompleted(true);

        const itemActivity: ItemActivityRequest = {
          from: variables.listing.seller,
          event: ItemActivityTypes.Listing,
          to: "--",
          price: variables.listing.price,
          collectionId: variables.listing.collection,
          tokenId: variables.listing.tokenId,
        };
        mutateAddItemActivity({ itemActivity });
      },
      onError: () => {
        setErrorMetaMask(true);
        setTitle("Error Listing");
        setContent("Please use open ticket for support in discord");
      },
    }
  );

  const onListing = async (listing: Listing) => {
    setListing(listing);
    try {
      if (!account) {
        return;
      }

      if (chainId !== process.env.REACT_APP_CHAIN_ID_PROD) {
        await switchChain("0x1");
        return;
      }

      setShowListingProgressDialong(true);
      let isAlreadyApproved = false;
      if (collection.token_type === TokenType.ERC721) {
        isAlreadyApproved = await isApproveERC721Collection(
          listing.seller,
          listing.collection
        );
        if (!isAlreadyApproved) {
          await setApproveERC721Collection(listing.seller, listing.collection);
        }
      } else if (collection.token_type === TokenType.ERC1155) {
        isAlreadyApproved = await isApproveERC1155Collection(
          listing.seller,
          listing.collection
        );
        if (!isAlreadyApproved) {
          await setApproveERC1155Collection(listing.seller, listing.collection);
        }
      }
      try {
        const typedData = getListingMsg(listing, chainId);
        const signature = await window.ethereum.request({
          method: "eth_signTypedData_v4",
          params: [account, JSON.stringify(typedData)],
          from: account,
        });
        listing.signature = signature;
        if (signature) {
          mutateListingRequest({ listing });
        }
      } catch (err) {
        setErrorMetaMask(true);
        setTitle("Failed to sign");
        setContent(
          "Please verify you are on the mainnet and wallet is unlocked"
        );
      }
    } catch (err) {
      setErrorMetaMask(true);
      setTitle("Approve collection");
      setContent(
        "Please approve your collection in order to be able to trading in Venus."
      );
    }
  };

  return (
    <>
      <Button className="list-token" onClick={() => setShowListingDialog(true)}>
        List now
      </Button>
      {showListingDialog && (
        <ListingTokenDialog
          listingAction={onListing}
          token={token}
          collection={collection}
          setShowMakeOfferDialog={setShowListingDialog}
          open={showListingDialog}
          nonce={dataLatestListingNonce}
        />
      )}
      {showListingProgressDialog && listing && (
        <ListingTokenProgress
          token={token}
          collection={collection}
          setShowListingProgressDialong={setShowListingProgressDialong}
          open={showListingProgressDialog}
          signListing={signListing}
          listCompleted={listCompleted}
          listing={listing}
        />
      )}
      {errorMetaMask ? (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      ) : undefined}
    </>
  );
};
interface ListingTokenContainerProps {
  token: Token;
  collection: CollectionInterface;
}
export default ListingTokenContainer;
