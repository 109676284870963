import { FC } from "react";

import "./emptyState.scss";

const EmptyState: FC<{
  textData: string;
}> = ({ textData }: { textData: string }) => {
  return (
    <div className="empty-state-container">
      <img src="/clock.svg" alt="" style={{ marginRight: "1rem" }} />
      <div className="no-data">{textData}</div>
      <div className="check-back-later">CHECK BACK LATER</div>
    </div>
  );
};

export default EmptyState;
