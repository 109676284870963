import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../../fetch/fetchApi";
import ItemActivity from "./itemActivity";
import { ItemActivityUI } from "../../../interfaces/itemActivity";
import { Token } from "../../../interfaces/token";

const ItemActivityContainer: FC<ItemActivityProps> = (
  props: ItemActivityProps
) => {
  const { token } = props;

  const [itemActivityList, setItemActivityList] = useState<ItemActivityUI[]>(
    []
  );

  //Call to server to bring data
  const { data: response, isLoading } = useQuery(
    ["get-token-activity", `${token.collection_id}/${token.token_id}`],
    () => get(`tokens/v1/${token.collection_id}/nft/${token.token_id}/activity`)
  );
  //put inside the state the result from API
  useEffect(() => {
    if (response) {
      const tempItemActivityList: ItemActivityUI[] = [];
      response.map((item: any) => {
        tempItemActivityList.push({
          event: item.activity_type,
          price: item.price,
          priceUsd: item.usd_price,
          from: item.from_address,
          to: item.to_address,
          date: item.created_at,
          collection_id: item.collection_id,
        });
      });
      setItemActivityList(tempItemActivityList);
    }
  }, [response, isLoading]);

  return <ItemActivity itemActivity={itemActivityList} isLoading={isLoading} />;
};

interface ItemActivityProps {
  token: Token;
}
export default ItemActivityContainer;
