import { Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../../../fetch/fetchApi";
import { Offer } from "../../../../interfaces/OfferTypes";
import { useMetaMask } from "metamask-react";
import { Token } from "../../../../interfaces/token";
import {
  getUserBalanceETH,
  getUserBalanceWETH,
  isApproveWETH,
  setApproveWETH,
} from "../../../../utils/web3Utils";
import MakeOfferDialog from "../makeOfferDialog/makeOfferDialog";
import { CollectionInterface } from "../../../../interfaces/collection";
import {
  ItemActivityRequest,
  ItemActivityTypes,
} from "../../../../interfaces/itemActivity";
import { useGetLatestOfferNonce } from "../../../../queries/nonce";
import SwapContainer from "../../swapEthWeth/swapEthWethContainer";
import { getOfferMsg } from "../../../../utils/signMessagesUtil";
import MakeOfferStepsDialog from "../../makeOfferStepsDialog/makeOfferStepsDialog";
import ConnectWallet from "../../../account/connectWallet";
import GlobalDialog from "../../../globalDialog/globalDialog";

const MakeTokenOfferContainer: FC<MakeTokenOfferContainerProps> = (
  props: MakeTokenOfferContainerProps
) => {
  const { token, collection } = props;
  const { account, chainId, connect, switchChain } = useMetaMask();
  const queryClient = useQueryClient();

  const [showMakeOfferDialog, setShowMakeOfferDialog] =
    useState<boolean>(false);
  const [showMakeOfferStepsDialog, setShowMakeOfferStepsDialog] =
    useState<boolean>(false);
  const [showSwapEthWethDialog, setShowSwapEthWethDialog] =
    useState<boolean>(false);
  const [isApprovedWethSpending, setIsApprovedWethSpending] =
    useState<boolean>(false);
  const [isOrderApproved, setIsOrderApproved] = useState<boolean>(false);
  const [isConfirmOrder, setIsConfirmOrder] = useState<boolean>(false);
  const [userBalanceWETH, setUserBalanceWETH] = useState<string>("");
  const [userBalanceETH, setUserBalanceETH] = useState<string>("");
  const [showWalletDialog, setShowWalletDialog] = useState<boolean>(false);
  const [offer, setOffer] = useState<Offer>();
  const [error, setError] = useState<boolean>(false);

  const title = "Error";
  const content = "Error to preform Offer, please open a ticket in discord";
  const handleConnect = async () => {
    const connectResponse = await connect();
    if (connectResponse) {
      setShowWalletDialog(false);
    }
  };

  const onMakeOfferDialog = (showMakeOfferDialog: boolean) => {
    if (account) {
      setShowMakeOfferDialog(showMakeOfferDialog);
    } else {
      setShowWalletDialog(true);
    }
  };
  useEffect(() => {
    if (!account) return;

    getUserBalanceWETH(account).then((data: string) => {
      setUserBalanceWETH(data.slice(0, 5));
    });
    getUserBalanceETH(account).then((data: string) => {
      setUserBalanceETH(data.slice(0, 5));
    });
  }, []);

  const { data: dataLatestOfferNonce } = useGetLatestOfferNonce(
    account || "",
    token
  );
  const addItemActivity = async (input: {
    itemActivity: ItemActivityRequest;
  }): Promise<any> => {
    const { itemActivity } = input;
    return await post("tokens/v1/activity", {
      collection_id: token.collection_id,
      token_id: token.token_id,
      activity_type: itemActivity.event,
      from_address: itemActivity.from,
      to_address: itemActivity.to,
      price: itemActivity.price,
    });
  };

  const { mutate: mutateAddItemActivity } = useMutation(addItemActivity, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "get-token-activity",
        `${variables.itemActivity.collectionId}/${variables.itemActivity.tokenId}`,
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const sendOfferCreatorWithSigned = async (input: {
    offer: Offer;
  }): Promise<any> => {
    const { offer } = input;
    return await post("tokens/v1/add-offer", {
      buyer: offer.buyer,
      collection_id: offer.collection,
      token_id: offer.tokenId,
      price: offer.price,
      amount: offer.amount,
      strategy: offer.strategy,
      currency: offer.currency,
      nonce: dataLatestOfferNonce,
      start_time: offer.startTime,
      end_time: offer.endTime,
      params: offer.params,
      signature: offer.signature,
    });
  };

  const { mutate: mutateCreateOffer } = useMutation(
    sendOfferCreatorWithSigned,
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries([
          "get-token-by-collection-id",
          variables.offer.collection,
          variables.offer.tokenId,
        ]);
        setIsOrderApproved(true);
        const itemActivity: ItemActivityRequest = {
          from: variables.offer.buyer,
          event: ItemActivityTypes.Offer,
          to: token.owner,
          price: variables.offer.price,
          collectionId: token.collection_id,
          tokenId: variables.offer.tokenId,
        };
        mutateAddItemActivity({ itemActivity });
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  const onMakeOffer = async (offer: Offer) => {
    if (!account) {
      //TODO: navigate to login / render dialog of login first
      return;
    }

    if(!chainId) {
      return;
    }

    if (chainId !== process.env.REACT_APP_CHAIN_ID_PROD) {
      await switchChain("0x1");
      return;
    }

    try {
      setOffer(offer);
      setShowMakeOfferDialog(false);
      setShowMakeOfferStepsDialog(true);
      const allowance = await isApproveWETH(account, offer.price);
      const approve = allowance
        ? true
        : await setApproveWETH(account, offer.price);
      if (approve) {
        setIsApprovedWethSpending(true);
        const typedData = getOfferMsg(offer, chainId);
        offer.signature = await window.ethereum.request({
          method: "eth_signTypedData_v4",
          params: [account, JSON.stringify(typedData)],
        });
        setIsConfirmOrder(true);
        mutateCreateOffer({ offer });
      } else {
        //render error must approve
      }
    } catch (err) {
      setOffer(undefined);
      setIsApprovedWethSpending(false);
      setIsConfirmOrder(false);
      setShowMakeOfferStepsDialog(false);
      setError(true);
    }
  };

  return (
    <>
      <Button className="make-offer" onClick={() => onMakeOfferDialog(true)}>
        Make offer
      </Button>
      {showMakeOfferDialog && (
        <MakeOfferDialog
          makeOffer={onMakeOffer}
          token={token}
          collection={collection}
          setShowMakeOfferDialog={setShowMakeOfferDialog}
          open={showMakeOfferDialog}
          nonce={dataLatestOfferNonce}
          userBalanceWETH={userBalanceWETH}
          userBalanceETH={userBalanceETH}
          setShowSwapEthWethDialog={setShowSwapEthWethDialog}
        />
      )}
      {showMakeOfferStepsDialog && offer && (
        <MakeOfferStepsDialog
          token={token}
          collection={collection}
          offer={offer}
          setShowMakeOfferStepsDialog={setShowMakeOfferStepsDialog}
          open={showMakeOfferStepsDialog}
          isApprovedWethSpending={isApprovedWethSpending}
          setIsApprovedWethSpending={setIsApprovedWethSpending}
          isConfirmOrder={isConfirmOrder}
          setIsConfirmOrder={setIsConfirmOrder}
          isOrderApproved={isOrderApproved}
        />
      )}
      {showSwapEthWethDialog && (
        <SwapContainer
          setShowDwapDialog={setShowSwapEthWethDialog}
          showDialog={showSwapEthWethDialog}
        />
      )}
      {showWalletDialog && (
        <ConnectWallet
          connect={handleConnect}
          open={showWalletDialog}
          setShowWalletDialog={setShowWalletDialog}
        />
      )}
      {error && (
        <GlobalDialog
          isOpen={error}
          setIsOpen={setError}
          title={title}
          content={content}
        />
      )}
    </>
  );
};
interface MakeTokenOfferContainerProps {
  collection: CollectionInterface;
  token: Token;
}
export default MakeTokenOfferContainer;
