import { FC } from "react";
import classnames from "classnames";
import "./loader.scss";

const Loader: FC<LoaderProps> = (props: LoaderProps) => {
  const { size = "100px", showBackground = true, className = "" } = props;

  return (
    <div
      className={classnames(
        {
          "loader-wrapper": !showBackground,
          "loader-wrapper-with-background": showBackground,
        },
        className
      )}
      style={{ "--size": `${size}`, marginTop: "2rem" } as any}
    >
      <div className="spinner">
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
        <div className="spinner-item"></div>
      </div>
    </div>
  );
};

interface LoaderProps {
  size?: string;
  className?: string;
  showBackground?: boolean;
}

export default Loader;
