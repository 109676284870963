import { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button, Checkbox, Divider, TextField } from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import CheckBoxIcon from "../icons/checkBox";
import MarkCheckBoxIcon from "../icons/markedCheckbox";
import SearchToken from "../search/searchForToken";
import { Token } from "../../interfaces/token";
import TokenCard from "../tokenCard/tokenCard";
import MakeCollectionOfferContainer from "../tokensActions/offerAction/makeCollectionOfferContainer/makeCollectionOfferContainer";
import TokenCardSkeleton from "../tokenCard/tokenCardSkeletor";
import "./explore.scss";
import { CollectionInterface } from "../../interfaces/collection";
import EmptyState from "../emptyState/emptyState";
import Loader from "../loader/loader";

const Explore: FC<ExploreProps> = (props: ExploreProps) => {
  const {
    tokens,
    collection,
    setTokens,
    isLoading,
    onLoadMore,
    hasMore,
    setSearchText,
  } = props;
  const [checked, setChecked] = useState(true);
  const navigate = useNavigate();
  const [hasOffers, setHasOffers] = useState(false);
  const [hasListing, setHasListing] = useState(false);
  const [orginalTokens, setOrginalTokens] = useState<Token[]>([]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleClick = (token: Token) => {
    navigate(`/collection/${token.collection_id}/token/${token.token_id}`);
  };

  useEffect(() => {
    if (hasOffers) {
      setOrginalTokens(tokens);
      const tempTokens = tokens.filter((token) => token.offers.length > 0);
      setTokens(tempTokens);
    } else {
      setTokens(orginalTokens);
    }
  }, [hasOffers]);

  useEffect(() => {
    if (hasListing) {
      setOrginalTokens(tokens);
      const tempTokens = tokens.filter((token) => token.listing !== null);
      setTokens(tempTokens);
    } else {
      setTokens(orginalTokens);
    }
  }, [hasListing]);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastRowElementRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          !isLoading && !hasListing && !hasOffers && hasMore && onLoadMore?.();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, onLoadMore]
  );

  return (
    <div className="nft-explore">
      <div className="search-and-filters">
        <SearchToken
          setTokens={setTokens}
          collection={collection}
          setSearchText={setSearchText}
        />
        <MakeCollectionOfferContainer collection={collection} />
      </div>
      <div className="filter-and-nfts">
        <div className="filters">
          <Accordion defaultExpanded={true} className="filter-header">
            <AccordionSummary
              sx={{ color: "white" }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white !important" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="filter-header">Status</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup className="filters-text">
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxIcon />}
                      checkedIcon={<MarkCheckBoxIcon />}
                      checked={hasListing}
                      onChange={(event) => setHasListing(event.target.checked)}
                    />
                  }
                  label="Has Listing"
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<CheckBoxIcon />}
                      checkedIcon={<MarkCheckBoxIcon />}
                      checked={hasOffers}
                      onChange={(event) => setHasOffers(event.target.checked)}
                    />
                  }
                  label="Has offers"
                />
              </FormGroup>
            </AccordionDetails>
          </Accordion>
          <Divider light className="divider" />
          <Accordion defaultExpanded={true} className="filter-header">
            <AccordionSummary
              sx={{ color: "white" }}
              expandIcon={<ExpandMoreIcon sx={{ color: "white !important" }} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="filter-header">PRICE</Typography>
            </AccordionSummary>
            <AccordionDetails className="min-max">
              <TextField sx={{ marginRight: "1rem" }} placeholder="MIN" />
              TO
              <TextField sx={{ marginLeft: "1rem" }} placeholder="MAX" />
            </AccordionDetails>
          </Accordion>
          <Divider light className="divider" />
        </div>
        <div className="nft-list">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {isLoading && tokens.length == 0 ? (
              <TokenCardSkeleton totalTokens={collection.total_tokens} />
            ) : (
              tokens?.map((token: any, i) => (
                <TokenCard
                  collection={collection}
                  refFunction={
                    tokens.length === i + 1 ? lastRowElementRef : undefined
                  }
                  token={token}
                  key={i}
                  onClick={() => handleClick(token)}
                />
              ))
            )}
            {isLoading && <Loader />}
          </div>
        </div>
      </div>
    </div>
  );
};

interface ExploreProps {
  tokens: Token[];
  collection: CollectionInterface;
  setTokens: Function;
  isLoading: boolean;
  onLoadMore: Function;
  setSearchText: Function;
  hasMore: boolean;
}

export default Explore;
