import { Grid } from "@mui/material";
import { FC } from "react";
import { CollectionInterface } from "../../interfaces/collection";
import CollectionsSkeleton from "./collectionsSkeleton";
import TopCollection from "./topCollection";
import "./topCollections.scss";

const TopCollections: FC<TopCollectionsProps> = (
  props: TopCollectionsProps
) => {
  const { collections, isLoading } = props;

  return (
    <div className="top-collections-wrapper">
      <div className="title-linear">
        <div className="title">TOP</div>
        <div className="text-linear-title">COLLECTIONS</div>
      </div>
      <Grid container justifyContent="center" spacing="8">
        {!isLoading ? (
          collections?.map((collection, i) => (
            <Grid key={i} item>
              <TopCollection key={i} collection={collection} />
            </Grid>
          ))
        ) : (
          <CollectionsSkeleton />
        )}
      </Grid>
    </div>
  );
};
interface TopCollectionsProps {
  collections: CollectionInterface[];
  isLoading: boolean;
}

export default TopCollections;
