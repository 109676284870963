import { useState, FC } from "react";
import { Select, MenuItem, FormControl } from "@mui/material";
import { Dayjs } from "dayjs";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const CustomDurationPicker: FC<CustomDurationPickerProps> = (
  props: CustomDurationPickerProps
) => {
  const { setDate, date } = props;
  const [selectedDuration, setSelectedDuration] = useState("1 week");

  const durations = ["1 day", "1 week", "1 month", "3 months", "6 months"];

  const handleDurationChange = (event: any) => {
    setSelectedDuration(event.target.value);
  };

  return (
    <>
      <FormControl>
        {/* <Select
          sx={{ fontFamily: "LemonMilk", width: "10rem" }}
          value={selectedDuration}
          onChange={handleDurationChange}
          displayEmpty
        >
          {durations.map((duration) => (
            <MenuItem
              sx={{ fontFamily: "LemonMilk" }}
              key={duration}
              value={duration}
            >
              {duration}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          toolbarPlaceholder="Basic example"
          value={date}
          inputFormat="DD/MM/YYYY"
          onChange={(newValue) => {
            setDate(newValue);
          }}
          minDate={new Date(Date.now() + 86400000)}
          renderInput={(params) => (
            <TextField sx={{ fontFamily: "LemonMilk" }} {...params} />
          )}
        />
      </LocalizationProvider>
    </>
  );
};
interface CustomDurationPickerProps {
  setDate: Function;
  date: Dayjs;
}
export default CustomDurationPicker;
