import Button from "@mui/material/Button";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import "./header.scss";

const Header: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="header-wrapper">
      <div className="title-wrapper">
        <div className="title">DISCOVER, COLLECT AND SELL NFT</div>
        <div className="sub-title">
          Once turned into an NFT it became a glitch in the matrix. Buy, sell,
          and discover rare digital art.
        </div>
        <div className="buttons">
          <Button className="gradient-btn" onClick={() => navigate("/explore")}>
            Explore
          </Button>
          {/* <Button className="button-with-linear-big">Create</Button> */}
        </div>
      </div>
      <div className="logo">
        <img src="/home-min.png" alt="" width="100%" />
      </div>
    </div>
  );
};

export default Header;
