import { FC } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { StyledEngineProvider } from "@mui/material/styles";
import { SkeletonTheme } from "react-loading-skeleton";
import { QueryClientProvider } from "react-query";
import UseQueryClient from "../useQueryClient";
import HomePageContainer from "../components/homePage/HomePageContainer";
import CollectionContainer from "../components/collectionContainer/collectionContainer";
import NavBar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";
import ExploreContainer from "../components/explore/exploreContainer";
import TokenContainer from "../components/tokenDetailsPage/tokenContainer/tokenContainer";
import CollectionsStats from "../components/collectionsStats/collectionsStats";
import { MetaMaskProvider } from "metamask-react";
import AccountContainer from "../components/account/accountContainer";
import TermsOfService from "../components/termsOfService/termsOfService";

const InnerRoutes: FC = () => {
  const queryClient = UseQueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <MetaMaskProvider>
          <SkeletonTheme
            baseColor="rgba(52, 102, 169, 0.5)"
            highlightColor="rgba(144, 22, 98, 0.5)
"
          >
            <div className="home-wrapper">
              <BrowserRouter>
                <NavBar />
                <Routes>
                  <Route path="/" element={<HomePageContainer />}></Route>
                  <Route path="/explore" element={<ExploreContainer />}></Route>
                  <Route path="/stats" element={<CollectionsStats />}></Route>
                  <Route
                    path="/collection/:id"
                    element={<CollectionContainer />}
                  />
                  <Route
                    path="/collection/:collectionId/token/:tokenId"
                    element={<TokenContainer />}
                  />

                  <Route
                    path="/account/:walletAddress"
                    element={<AccountContainer />}
                  />

                  <Route path="/account" element={<AccountContainer />} />
                  <Route path="/terms" element={<TermsOfService />} />
                </Routes>
                <Footer />
              </BrowserRouter>
            </div>
          </SkeletonTheme>
        </MetaMaskProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
};

export default InnerRoutes;
