import { FC, useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useQuery } from "react-query";
import { get } from "../../fetch/fetchApi";
import Collections from "../collections/collections";
import EmptyState from "../emptyState/emptyState";
import { CollectionInterface } from "../../interfaces/collection";
import { TimePicker } from "../collectionsStats/timePicker/timePicker";
import Loader from "../loader/loader";

const ExploreContainer: FC = () => {
  const [collections, setCollections] = useState<CollectionInterface[]>([]);
  const [timePicked, setTimePicked] = useState("all");
  const [value, setValue] = useState(0);

  const { data: collectionsResponse, isLoading } = useQuery(
    ["explore-collections", timePicked],
    () => get(`collections/v1/top?interval=${timePicked}`),
    {
      enabled: !!timePicked,
    }
  );

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (collectionsResponse) {
      setCollections(collectionsResponse);
    }
  }, [collectionsResponse]);

  return (
    <div className="container">
      <Tabs className="tabs" value={value} onChange={handleChange}>
        <Tab label="Collections" />
      </Tabs>
      <div>
        <TimePicker setTimePicked={setTimePicked} />
      </div>
      {!isLoading ? <Collections collections={collections} /> : <Loader />}
    </div>
  );
};

export default ExploreContainer;
