import Accordion from "@mui/material/Accordion";
import React, { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import "./description.scss";

const Description: FC<DescriptionProps> = (props: DescriptionProps) => {
  const { description } = props;
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="description-container"
      expanded={expanded}
      onChange={() => handleChange()}
    >
      <AccordionSummary
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <img
          src="/description.svg"
          alt="description-img"
          style={{ marginRight: "1rem" }}
        />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>
          DESCRIPTION
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ fontFamily: "LemonMilk-light" }}>
        <div className="description">{description}</div>
      </AccordionDetails>
    </Accordion>
  );
};
interface DescriptionProps {
  description: string | undefined;
}
export default Description;
