import { Addresses } from "./types";

export enum SupportedChainId {
  MAINNET_HEX = "0x1",
  MAINNET = "1",
  GOERLI_HEX = "0x5",
  GOERLI = "5",
}

export const mainnetAddresses: Addresses = {
  WETH: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
  CURRENCY_MANAGER: "0xd22398362744Aef3f942B950a9E1aeEbc0F904Ef",
  EXECUTION_MANAGER: "0x53A3C7991d377da4471E6cB7B6192f1F686ad60D",
  STRATEGY_STANDARD_SALE_SINGLE_ITEM: "0xEAC683807bb9afd54de266EA5A812bf219595ca3",
  STRATEGY_STANDARD_SALE_SINGLE_ITEM_IN_COLLECTION: "0x599A0B2F2f7A97B34B9dF7852C337A608B22f012",
  VENUS_MARKETPLACE: "0x2cB267666b768EC0A7cC14a47cd07E359E41B58E",
  TRANSFER_MANAGER_ERC721: "0x2aB1760b19c27c0dC342f7bFFf8B96Fea438eCAb",
  TRANSFER_MANAGER_ERC1155: "0xD030D3d3799687c0d552db969627B0a3d8532063",
  TRANSFER_SELECTOR_NFT: "0x45C2568872CBABdf9c1dC884675D529549F62aF5",
};

export const goerliAddresses: Addresses = {
  WETH: "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6",
  CURRENCY_MANAGER: "0xc3637dAC847c9fC168A9Cc926A5377Aca93b0666",
  EXECUTION_MANAGER: "0x2b21c734C3479f2C2462A23Aa760e545855E7d7A",
  STRATEGY_STANDARD_SALE_SINGLE_ITEM:
    "0xc5412EfBc2Bd2CF1160dA0EE496A2909602304C6",
  STRATEGY_STANDARD_SALE_SINGLE_ITEM_IN_COLLECTION:
    "0x63A02Db57299A89b37B4f11E6E8889B48F81a6a1",
  VENUS_MARKETPLACE: "0xDEaE4dA5235CBaF16077785E2d6b7c4eAB2EEFB2",
  TRANSFER_MANAGER_ERC721: "0x6b29Bf5aC32240cF64a7C1e69b57f553355D1b3A",
  TRANSFER_MANAGER_ERC1155: "0xb82c263D2CAf94cEBC259627030BCF3503080484",
  TRANSFER_SELECTOR_NFT: "0x996c974D2dEA016eda17A4CC7945d2831FDF7bfc",
};

export const addressesByNetwork = (chainId: string) => {
  switch (chainId) {
    case SupportedChainId.MAINNET:
    case SupportedChainId.MAINNET_HEX:
      return mainnetAddresses;
    case SupportedChainId.GOERLI:
    case SupportedChainId.GOERLI_HEX:
      return goerliAddresses;
    default:
      throw new Error("Unsupported network");
  }
};
