import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell, Tooltip } from "@mui/material";
import { FC, useEffect, useState } from "react";
import "./collectionsStatsTable.scss";
import Button from "@mui/material/Button";
import { UseQueryResult } from "react-query";
import { getShortenCollectionName } from "../../../utils/util";
import { CollectionStats } from "../../../interfaces/collection";
import { convertWeiToETH } from "../../../utils/web3Utils";

interface CollectionsStatsTableProps {
  timePicked: string;
  useFetchQuery: (
    offset: number,
    timePicked: string
  ) => UseQueryResult<Array<CollectionStats>, Error>;
}

export const CollectionsStatsTable: FC<CollectionsStatsTableProps> = (
  props
) => {
  const [collections, setCollections] = useState<Array<CollectionStats>>([]);
  const { timePicked, useFetchQuery } = props;
  const { data, isError } = useFetchQuery(0, "24H");

  useEffect(() => {
    if (!data || isError) return;
    setCollections(data);
  }, [data, isError, timePicked]);

  const onShowMoreClicked = () => {};

  return (
    <div>
      <TableContainer component={Paper} sx={{ background: "transparent" }}>
        <Table stickyHeader aria-label="collections stats">
          <TableHead>
            <TableRow>
              <TableCell>COLLECTION</TableCell>
              <TableCell>VOLUME</TableCell>
              <TableCell>FlOOR&nbsp;PRICE</TableCell>
              <TableCell>SALES</TableCell>
              <TableCell>UNIQUE&nbsp;OWNERS</TableCell>
              <TableCell>ITEMS&nbsp;LISTED</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections.map((collection) => (
              <TableRow key={collection.id}>
                <TableCell className="collection-image-cell">
                  <img
                    className="collection-image"
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTcLd628qXMxdk1j8Y2_bZ-wPGrS1jAnTy0DA&usqp=CAU"
                    alt=""
                  />
                  <Tooltip title={collection.name} placement="top">
                    <div>{getShortenCollectionName(collection.name, 10)}</div>
                  </Tooltip>
                </TableCell>
                <TableCell>{collection.volume}</TableCell>
                <TableCell>
                  <div>
                    {" "}
                    {convertWeiToETH(collection?.floor_price.toString())}
                  </div>
                  <img src="/eth.svg" alt="" />
                </TableCell>
                <TableCell>{collection.sales}</TableCell>
                <TableCell>{collection.unique_owners}</TableCell>
                <TableCell>{collection.items_listed}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button onClick={onShowMoreClicked} className="show-more-gradient-btn">
          Show More
        </Button>
      </div>
    </div>
  );
};
