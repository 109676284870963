import Web3 from "web3";
import { ethers } from "ethers";
import ERC721 from "../contracts/erc721.json";
import ERC1155 from "../contracts/erc1155.json";
import WETH from "../contracts/weth.json";
import { AbiItem } from "web3-utils";
import { Token } from "../interfaces/token";
import { addressesByNetwork } from "../constants/addresses";

export const getUserBalanceETH = async (address: string) => {
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  address = ethers.utils.getAddress(address);
  const balance = await provider.getBalance(address);
  return ethers.utils.formatEther(balance);
};

export const getUserBalanceWETH = async (address: string) => {
  const web3 = new Web3(window.ethereum);
  address = ethers.utils.getAddress(address);
  const contractInstance = new web3.eth.Contract(
    WETH.abi as AbiItem[],
    addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1").WETH
  );
  try {
    const balance = await contractInstance.methods.balanceOf(address).call();
    return ethers.utils.formatEther(balance);
  } catch (err) {
    console.log(err);
    return "0";
  }
};

export const convertWeiToETH = (wei: string) => {
  return Web3.utils.fromWei(wei.toString());
};

export const convertToETH = (balance: string) => {
  return ethers.utils.formatEther(balance);
};

export const convertETHtoWei = (eth: string) => {
  try {
    return Web3.utils.toWei(eth).toString();
  } catch (err) {
    return "0";
  }
};

export const setApproveERC721Collection = async (
  account: string,
  collectionId: string
) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    ERC721.abi as AbiItem[],
    collectionId
  );
  try {
    return await contractInstance.methods
      .setApprovalForAll(
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .TRANSFER_MANAGER_ERC721,
        true
      )
      .send({ from: account });
  } catch (err) {
    throw err;
  }
};

export const isApproveERC721Collection = async (
  account: string,
  collectionId: string
) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    ERC721.abi as AbiItem[],
    collectionId
  );
  try {
    return await contractInstance.methods
      .isApprovedForAll(
        account,
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .TRANSFER_MANAGER_ERC721
      )
      .call();
  } catch (err) {
    throw err;
  }
};

export const TransferERC721Token = async (
  from: string,
  to: string,
  token: Token,
  chainId: string
) => {
  const web3 = new Web3(window.ethereum);

  if (chainId.toString() !== process.env.REACT_APP_CHAIN_ID_PROD) {
    return false;
  }
  const contractInstance = new web3.eth.Contract(
    ERC721.abi as AbiItem[],
    token.collection_id
  );
  try {
    return await contractInstance.methods
      .transferFrom(from, to, token.token_id)
      .send({ from: from });
  } catch (err) {
    throw err;
  }
};

export const setApproveERC1155Collection = async (
  account: string,
  collectionId: string
) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    ERC1155.abi as AbiItem[],
    collectionId
  );
  try {
    return await contractInstance.methods
      .setApprovalForAll(
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .TRANSFER_MANAGER_ERC1155,
        true
      )
      .send({ from: account });
  } catch (err) {
    throw err;
  }
};

export const isApproveERC1155Collection = async (
  account: string,
  collectionId: string
) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    ERC1155.abi as AbiItem[],
    collectionId
  );
  try {
    return await contractInstance.methods
      .isApprovedForAll(
        account,
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .TRANSFER_MANAGER_ERC1155
      )
      .call();
  } catch (err) {
    throw err;
  }
};

export const TransferERC1155Token = async (
  from: string,
  to: string,
  token: Token,
  chainId: string
) => {
  const web3 = new Web3(window.ethereum);
  if (chainId.toString() !== process.env.REACT_APP_CHAIN_ID_PROD) {
    return false;
  }
  const contractInstance = new web3.eth.Contract(
    ERC1155.abi as AbiItem[],
    token.collection_id
  );
  try {
    return await contractInstance.methods
      .transferFrom(from, to, token.token_id)
      .send({ from: from });
  } catch (err) {
    throw err;
  }
};

export const depositWETH = async (account: string, amount: string) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    WETH.abi as AbiItem[],
    addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1").WETH
  );
  try {
    return await contractInstance.methods
      .deposit()
      .send({ from: account, value: amount });
  } catch (err) {
    console.log(err);
  }
};

export const isApproveWETH = async (account: string, amount: string) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    WETH.abi as AbiItem[],
    addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1").WETH
  );
  try {
    const allowance = await contractInstance.methods
      .allowance(
        account,
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .VENUS_MARKETPLACE
      )
      .call();
    return allowance >= Number(amount);
  } catch (err) {
    console.log(err);
  }
};

export const setApproveWETH = async (account: string, amount: string) => {
  const web3 = new Web3(window.ethereum);
  const contractInstance = new web3.eth.Contract(
    WETH.abi as AbiItem[],
    addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1").WETH
  );
  try {
    return await contractInstance.methods
      .approve(
        addressesByNetwork(process.env.REACT_APP_CHAIN_ID_PROD || "1")
          .VENUS_MARKETPLACE,
        amount
      )
      .send({ from: account });
  } catch (err) {
    console.log(err);
    throw err;
  }
};
