import { FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchCollection from "../search/searchForCollection";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import "./navbar.scss";
import ConnectWallet from "../account/connectWallet";
import { useMetaMask } from "metamask-react";
import GlobalDialog from "../globalDialog/globalDialog";
import { Menu, MenuItem } from "@mui/material";

const NavBar: FC = () => {
  const { status, connect, account } = useMetaMask();
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);
  const [showWalletDialog, setShowWalletDialog] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>("/metamask.png");
  const navigate = useNavigate();

  const handleLogin = (event: any) => {
    if (status === "connected") {
      navigate(`/account`);
      return;
    }
    setShowWalletDialog(true);
  };

  const handleConnect = async () => {
    const connectResponse = await connect();
    if (connectResponse) {
      setShowWalletDialog(false);
      setImgSrc("VN");
    }
  };

  return (
    <>
      <AppBar
        position="static"
        sx={{ background: "transparent", marginTop: "0.5rem" }}
      >
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <div className="venus-logo" onClick={() => navigate("/")}>
              <img src="/Venus-logo.svg" alt="" />
            </div>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              className="text-linear-title"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "LemonMilk-bold",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
                marginLeft: "1rem",
              }}
            >
              VENUS
            </Typography>
            {/* <Typography
              noWrap
              component="div"
              sx={{
                display: { xs: "none", sm: "block" },
                fontFamily: "LemonMilk",
              }}
            >
              Explore
            </Typography> */}

            <Typography
              variant="h5"
              noWrap
              component="a"
              className="text-linear-title"
              href=""
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              Venus
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <SearchCollection />
            </Box>
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleLogin} sx={{ p: 0 }}>
                <Avatar
                  alt="V N"
                  src={status === "connected" ? "VN" : imgSrc}
                />
              </IconButton>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      {showWalletDialog && (
        <ConnectWallet
          connect={handleConnect}
          open={showWalletDialog}
          setShowWalletDialog={setShowWalletDialog}
        />
      )}
      {errorMetaMask && (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      )}
    </>
  );
};

export default NavBar;
