import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Action, ActionType } from "../../interfaces/nftActions";
import { CollectionInterface } from "../../interfaces/collection";
import { useMetaMask } from "metamask-react";
import "./collectionCard.scss";

const CollectionCard: FC<CollectionCardProps> = (
  props: CollectionCardProps
) => {
  const { collection, onClick, actions } = props;
  const { account } = useMetaMask();
  const [showActions, setShowActions] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOnClick = () => {
    if (actions) {
      setShowActions(true);
    }
  };

  const handleActionOnClick = async (action: Action) => {
    if (action.type === ActionType.Edit) {
      const message = `Please sign this message to log in.This won't cost you any ETH! By signing, you accept Venus Marketplace's Terms of Service, which you can find here: https://www.venus-market.io/terms ${new Date()}`;
      const msg = `0x${Buffer.from(message, "utf8").toString("hex")}`;
      const sign = await window.ethereum.request({
        method: "personal_sign",
        params: [msg, account],
      });
      navigate(`collection/edit/${collection.collection_id}`);
    }
    setShowActions(false);
  };

  return (
    <div className="collection-card-wrapper">
      <div className="collection-card-image" onClick={() => onClick()}>
        <img
          src={collection.image_url ? collection.image_url : "/noContent.svg"}
          alt=""
          className="my-collection-image"
        />
        <div className="image-overly"></div>
      </div>
      <div className="collection-card-details">
        <div className="collection-card-token-name">{collection.name}</div>

        {actions && (
          <div className="collection-actions" onClick={handleOnClick}>
            <img src="/dots.svg" alt="" />
          </div>
        )}
        {actions && showActions && (
          <div className="actions-wrapper">
            {actions.map((action, i) => (
              <div
                className="action"
                onClick={() => handleActionOnClick(action)}
                key={i}
              >
                <div className="text">{action.title}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface CollectionCardProps {
  collection: CollectionInterface;
  onClick: Function;
  actions?: Action[];
  ref?: Function;
}

export default CollectionCard;
