import { FC } from "react";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";
import { useFetchEthUsdPrice } from "../../../queries/ethDollar";
import TokenAuctionDetails from "../tokenAuctionDetails/tokenAuctionDetails";

const TokenActionsContainer: FC<TokenActionsProps> = (
  props: TokenActionsProps
) => {
  const { token, collection } = props;
  const { data: ethUsdPrice } = useFetchEthUsdPrice();

  return (
    <TokenAuctionDetails
      token={token}
      collection={collection}
      ethUsdPrice={ethUsdPrice}
    />
  );
};
interface TokenActionsProps {
  token: Token;
  collection: CollectionInterface;
}

export default TokenActionsContainer;
