import { Button } from "@mui/material";
import { FC, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../../fetch/fetchApi";
import {
  TransferERC1155Token,
  TransferERC721Token,
} from "../../../utils/web3Utils";
import GlobalDialog from "../../globalDialog/globalDialog";
import { CollectionInterface } from "../../../interfaces/collection";
import {
  ItemActivityRequest,
  ItemActivityTypes,
} from "../../../interfaces/itemActivity";
import { Token } from "../../../interfaces/token";
import TransferTokenDialog from "../transferTokenDialog/trasnferTokenDialog";
import "./transferTokenContainer.scss";
import TransferTokenProgressDialog from "../transferTokenProgressDialog/transferTokenProgressDialog";
import { TokenType } from "../../../interfaces/tokenTypeEnum";
import { useMetaMask } from "metamask-react";

const TransferTokenContainer: FC<TransferTokenContainerProps> = (
  props: TransferTokenContainerProps
) => {
  const { token, collection } = props;
  const { chainId } = useMetaMask();
  const queryClient = useQueryClient();
  const [showTransferDialog, setShowTransferDialog] = useState<boolean>(false);
  const [showTransferProgressDialog, setShowTransferProgressDialog] =
    useState<boolean>(false);

  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);

  const title = "Error";
  const content = "Error to do Transfer, please open a ticket in discord";

  const addItemActivity = async (input: {
    itemActivity: ItemActivityRequest;
  }): Promise<any> => {
    const { itemActivity } = input;
    const response = await post("tokens/v1/activity", {
      collection_id: itemActivity.collectionId,
      token_id: itemActivity.tokenId,
      activity_type: itemActivity.event,
      from_address: itemActivity.from,
      to_address: itemActivity.to,
      price: itemActivity.price,
    });
    return response;
  };

  const { mutate: mutateAddItemActivity } = useMutation(addItemActivity, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([
        "get-token-activity",
        `${variables.itemActivity.collectionId}/${variables.itemActivity.tokenId}`,
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const onTransfer = async (from: string, to: string, token: Token) => {
    try {
      setShowTransferProgressDialog(true);
      let transferToken = false;
      if (chainId) {
        if (collection.token_type === TokenType.ERC721) {
          transferToken = await TransferERC721Token(from, to, token, chainId);
        } else if (collection.token_type === TokenType.ERC1155) {
          transferToken = await TransferERC1155Token(from, to, token, chainId);
        }
      }

      if (transferToken) {
        //render / navigate success message
        const itemActivity: ItemActivityRequest = {
          from: from,
          event: ItemActivityTypes.Trasnfer,
          to: to,
          price: "0",
          collectionId: token.collection_id,
          tokenId: token.token_id,
        };
        mutateAddItemActivity({ itemActivity });
      }
    } catch (err) {
      setShowTransferProgressDialog(false);
      setErrorMetaMask(true);
    }
  };

  return (
    <div style={{ margin: "0.5rem" }}>
      <Button
        className="list-token"
        onClick={() => setShowTransferDialog(true)}
      >
        Transfer Token
      </Button>
      {showTransferDialog && (
        <TransferTokenDialog
          transferAction={onTransfer}
          token={token}
          collection={collection}
          setShowTransferDialog={setShowTransferDialog}
          open={showTransferDialog}
        />
      )}
      {showTransferProgressDialog && (
        <TransferTokenProgressDialog
          token={token}
          collection={collection}
          setShowTransferProgressDialog={setShowTransferProgressDialog}
          open={showTransferProgressDialog}
        />
      )}
      {errorMetaMask ? (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      ) : undefined}
    </div>
  );
};
interface TransferTokenContainerProps {
  token: Token;
  collection: CollectionInterface;
}
export default TransferTokenContainer;
