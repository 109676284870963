import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./tokenCardSkeleton.scss";

const TokenCardSkeleton: FC<TokenCardSkeletonProps> = (
  props: TokenCardSkeletonProps
) => {
  const { totalTokens } = props;
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {Array.from(
        { length: totalTokens >= 16 ? 16 : totalTokens },
        (_, index) => (
          <div className="card-skeleton" key={index}>
            <div className="left-col">
              <Skeleton
                count={1}
                width={200}
                height={150}
                style={{ marginTop: "0.2rem" }}
              />
            </div>
            <div className="right-col">
              <Skeleton count={3} style={{ marginTop: "0.6rem" }} width={200} />
            </div>
          </div>
        )
      )}
    </div>
  );
};
interface TokenCardSkeletonProps {
  totalTokens: number;
}
export default TokenCardSkeleton;
