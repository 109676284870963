import { FC } from "react";
import { useNavigate } from "react-router-dom";
import "./footer.scss";

const Footer: FC = () => {
  const navigate = useNavigate();

  return (
    <div className="footer">
      <div className="logo-name-description-footer">
        <div className="logo-name-footer">
          <div className="logo-footer">
            <img src="/Venus-logo.svg" alt="" />
          </div>
          <div className="name-footer">Venus Platform</div>
        </div>

        <div className="descirption-footer">
          DIGITAL MARKETPLACE FOR CRYPTO COLLECTIBLES AND NON-FUNGIBLE TOKENS
          (NFTS). BUY, SELL, AND DISCOVER EXCLUSIVE DIGITAL ITEMS.
        </div>
      </div>
      <div className="links-social-footer">
        <div className="links-footer">
          <div className="explore" onClick={() => navigate("/terms")}>
            Terms of service
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <a href="https://twitter.com/VenusNFTMarket/">
            <img style={{ marginLeft: "0.5rem" }} src="/twiter.svg" alt="" />
          </a>

          <a href="https://www.facebook.com/VenusNFTMarket">
            <img style={{ marginLeft: "0.5rem" }} src="/facebook.svg" alt="" />
          </a>

          <a href="https://www.instagram.com/venusnftmarket/">
            <img style={{ marginLeft: "0.5rem" }} src="/instagram.svg" alt="" />
          </a>
          <img style={{ marginLeft: "0.5rem" }} src="/twitch.svg" alt="" />
        </div>
      </div>
    </div>
  );
};

export default Footer;
