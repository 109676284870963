import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";
import { Token } from "../interfaces/token";

export const getPriceHistory = "get-price-history";

export const useGetTokenPrice = (token: Token) => {
  return useQuery(
    [getPriceHistory, `${token.collection_id.toLowerCase()}/${token.token_id}`],
    () =>
      get(
        `tokens/v1/${token.collection_id.toLowerCase()}/nft/${
          token.token_id
        }/activity/price_history`
      )
  );
};
