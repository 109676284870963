import { FC } from "react";
import HomePage from "./homePage";
import "./homePage.scss";
import MobileHomePage from "./mobileHomePage";

const HomePageContainer: FC = () => {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  return <div>{isMobile ? <MobileHomePage /> : <HomePage />}</div>;
};

export default HomePageContainer;
