export const fetchSearchResults = async (searchTerm: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_API}/collections-search/${searchTerm}?offset=0`
  );
  return await res.json();
};

export const fetchSearchNFTResults = async (searchTerm: string) => {
  const res = await fetch(`${process.env.REACT_APP_API}/`);
  return await res.json();
};

export const fetchCollectionsStats = async (searchTerm: string) => {
  const res = await fetch(
    `${process.env.REACT_APP_API}/collections-search/${searchTerm}?offset=0`
  );
  return await res.json();
};

export const get = async (url: string, options = {}) => {
  const response = await fetch(`${process.env.REACT_APP_API}/${url}`, {
    ...options,
    method: "GET",
  });
  return await response.json();
};

export const post = async (url: string, data: any, options = {}) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API}/${url}`, {
      ...options,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    try {
      const json = await response.json();
      if (!response.ok) {
        throw new Error(response.status.toString());
      }
      return { response, json };
    } catch (err) {
      throw new Error(response.status.toString());
    }
  } catch (err) {
    console.log(err);
    throw new Error("error");
  }
};

export const put = async (url: string, data: any, options: {}) => {
  const response = await fetch(`${process.env.REACT_APP_API}/${url}`, {
    ...options,
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
  return await response.json();
};

export const deleteApi = async (url: string, options = {}) => {
  const response = await fetch(`${process.env.REACT_APP_API}/${url}`, {
    ...options,
    method: "DELETE",
  });
  return await response.json();
};
