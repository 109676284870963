import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { CollectionInterface } from "../../../interfaces/collection";
import { CircularProgress, DialogActions, Divider } from "@mui/material";
import { handleImageCard, handleTokenName } from "../../../utils/util";
import { Token } from "../../../interfaces/token";
import CheckIcon from "@mui/icons-material/Check";
import { Listing } from "../../../interfaces/ListingTypes";
import "./listingTokenProgress.scss";
import { convertWeiToETH } from "../../../utils/web3Utils";
import { useFetchEthUsdPrice } from "../../../queries/ethDollar";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ListingTokenProgress: FC<ListingTokenProgressProps> = (
  props: ListingTokenProgressProps
) => {
  const {
    token,
    open,
    collection,
    setShowListingProgressDialong,
    signListing,
    listCompleted,
    listing,
  } = props;

  const handleClose = () => {
    setShowListingProgressDialong(false);
  };
  const { data: ethUsdPrice } = useFetchEthUsdPrice();

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Listing Process
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="listing-container">
            <div className="offer-name-details-wrapper">
              <div className="offer-nft-name-icon-wrapper">
                <div className="offer-nft-icon">
                  <img
                    src={handleImageCard(token)}
                    width="100"
                    height="100"
                    alt="card-img"
                  />
                </div>
                <div className="nft-name-collection-wrapper">
                  <div className="offer-nft-name">{handleTokenName(token)}</div>
                  <div className="offer-nft-collection">{collection.name}</div>
                </div>
              </div>
              <div className="listing-token-details">
                <div className="offer-nft-price-eth">
                  floor price: {convertWeiToETH(collection?.floor_price)}
                </div>
                <div className="offer-nft-price-eth">
                  Your listing price:{convertWeiToETH(listing.price)} =
                  {(
                    parseFloat(convertWeiToETH(listing.price)) *
                    parseInt(ethUsdPrice)
                  ).toFixed(2) + "$"}
                </div>
              </div>
            </div>

            <Divider light className="divider" />
            <div className="offer-summary">
              {!signListing ? (
                <>
                  <div className="duration-const">Steps</div>
                  <div className="enable-weth-progress">
                    {!listCompleted ? (
                      <CircularProgress
                        color="inherit"
                        sx={{
                          width: "15px !important",
                          height: "15px !important",
                          size: "10px",
                          marginRight: "0.5rem",
                        }}
                      />
                    ) : (
                      <CheckIcon />
                    )}

                    <div className="const-text">
                      Create and publish your token for sale
                    </div>
                  </div>
                  {/* <div className="confirm-order">
                    {!isConfirmOrder ? (
                      <CircularProgress
                        color="inherit"
                        sx={{
                          width: "15px !important",
                          height: "15px !important",
                          size: "10px",
                          marginRight: "0.5rem",
                        }}
                      />
                    ) : (
                      <CheckIcon />
                    )}
                    <div className="const-text">Confirm Order</div>
                  </div> */}

                  <div className="const-text">
                    Please confirm the transaction in your wallet.
                  </div>
                </>
              ) : (
                <div className="order-approved-wrapper">
                  <div className="order-approved-title">
                    Transction done, Yay!
                  </div>
                  <div className="order-approved-value"></div>
                  <Divider light className="divider" />
                  <div className="const-text">
                    Successfully listing your token number {token.token_id} from
                    collection {collection.name}
                  </div>
                  <div className="offer-approved-validate"></div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          {/* <Button
            className="gradient-btn"
            autoFocus
            sx={{ marginBottom: "1rem" }}
            onClick={async () => await handleOnListingToken(toWallet, token)}
          >
            Transfer token
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface ListingTokenProgressProps {
  token: Token;
  collection: CollectionInterface;
  listing: Listing;
  setShowListingProgressDialong: Function;
  open: boolean;
  signListing: boolean;
  listCompleted: boolean;
}
export default ListingTokenProgress;
