import Accordion from "@mui/material/Accordion";
import { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell } from "@mui/material";
import EmptyState from "../../emptyState/emptyState";
import { displayLeftAuctionTime } from "../../../utils/util";
import { ListingDAO } from "../../../interfaces/ListingTypes";
import { useFetchEthUsdPrice } from "../../../queries/ethDollar";
import "./listing.scss";
import { convertWeiToETH } from "../../../utils/web3Utils";

const ListingComponent: FC<ListingProps> = (props: ListingProps) => {
  const { listing } = props;
  const { data: ethUsdPrice } = useFetchEthUsdPrice();

  const [expanded, setExpanded] = useState(false);
  const headers = ["PRICE", "USD PRICE", "EXPIRATION", "FROM"];

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="nft-listing-container"
      expanded={expanded}
      onChange={() => handleChange()}
    >
      <AccordionSummary
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
      >
        <img src="/listing.svg" alt="Image" style={{ marginRight: "1rem" }} />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>Listing</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontFamily: "LemonMilk-light",
          fontSize: "14px",
          padding: "0",
          marginTop: "0.5rem",
          background: "transparent !important",
        }}
      >
        <TableContainer component={Paper} sx={{ background: "transparent" }}>
          <Table stickyHeader aria-label="collections stats">
            {listing ? (
              <>
                <TableHead>
                  <TableRow>
                    {headers.map((header: string, i) => (
                      <TableCell>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div> {convertWeiToETH(listing?.price)}</div>
                        <img src="/eth.svg" alt="" />
                      </div>
                    </TableCell>
                    <TableCell>
                      {(
                        parseFloat(convertWeiToETH(listing.price)) *
                        parseInt(ethUsdPrice)
                      ).toFixed(2) + "$"}
                    </TableCell>
                    <TableCell>
                      {displayLeftAuctionTime(
                        listing.start_time,
                        listing.end_time
                      )}
                    </TableCell>
                    <TableCell>
                      <div className="listing-data">
                        {listing?.seller.slice(0, 10) + "..."}
                      </div>
                    </TableCell>
                    <TableCell>
                      <img src="/cart.svg" alt="" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </>
            ) : (
              <EmptyState textData={"No listing yet"} />
            )}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

interface ListingProps {
  listing?: ListingDAO;
  isOwner: boolean;
}

export default ListingComponent;
