import { FC } from "react";
import "./mobile.scss";

const MobileHomePage: FC = () => {
  return (
    <>
      <div className="title-mobile">Hey!</div>
      <div className="small-title-mobile">
        Application coming soon, check desktop version today!
      </div>
    </>
  );
};

export default MobileHomePage;
