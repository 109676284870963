import { Button } from "@mui/material";
import { FC, useState } from "react";
import { Token } from "../../../interfaces/token";
import VenusMarketPlace from "../../../contracts/marketplace.json";
import { AbiItem } from "web3-utils";
import { CollectionInterface } from "../../../interfaces/collection";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import { addressesByNetwork } from "../../../constants/addresses";
import { useQueryClient } from "react-query";
import CancelListingDialog from "./cancelListingDialog";

const CancelListingContainer: FC<CancelListingContainerProps> = (
  props: CancelListingContainerProps
) => {
  const { token, collection } = props;
  const queryClient = useQueryClient();
  const [cancelListingDialog, setCancelListingDialog] =
    useState<boolean>(false);
  const { account, chainId } = useMetaMask();

  const cancelListing = async (token: Token) => {
    const web3 = new Web3(window.ethereum);
    if (token.listing && account) {
      setCancelListingDialog(true);
      let signedTx;
      try {
        const venusMarketPlaceContract = new web3.eth.Contract(
          VenusMarketPlace.abi as AbiItem[],
          addressesByNetwork(chainId).VENUS_MARKETPLACE
        );

        const cancelListingGas = await venusMarketPlaceContract.methods
          .cancelMultipleListingsForSender([token.listing.nonce])
          .estimateGas({
            from: account,
          });

        const functionData = venusMarketPlaceContract.methods
          .cancelMultipleListingsForSender([token.listing.nonce])
          .encodeABI();
        const approveOffer = {
          to: addressesByNetwork(chainId).VENUS_MARKETPLACE,
          data: functionData,
          gas: cancelListingGas,
          from: account,
        };
        signedTx = await web3.eth.sendTransaction(approveOffer);
        if (signedTx) {
          queryClient.invalidateQueries([
            "get-token-by-collection-id",
            token.listing.collection_id.toLowerCase(),
            token.listing.token_id,
          ]);
          setCancelListingDialog(false);
        }
      } catch (err) {
        // setErrorMetaMask(true);
        // setTitle("Error Metamask");
        // setContent(
        //   "Please verify you are on the mainnet, and wallet is unlocked"
        // );
        setCancelListingDialog(false);
      }
    }
  };
  return (
    <>
      <Button className="buy-now" onClick={async () => cancelListing(token)}>
        Cancel Listing
      </Button>
      {cancelListingDialog && (
        <CancelListingDialog
          token={token}
          setCancelListingDialog={setCancelListingDialog}
          open={cancelListingDialog}
          collection={collection}
        />
      )}
    </>
  );
};
interface CancelListingContainerProps {
  token: Token;
  collection: CollectionInterface;
}
export default CancelListingContainer;
