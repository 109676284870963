import Accordion from "@mui/material/Accordion";
import { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import EmptyState from "../../emptyState/emptyState";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell, Tooltip } from "@mui/material";
import {
  calcFloorDifference,
  checkOfferMaker,
  displayLeftAuctionTime,
} from "../../../utils/util";
import "./offers.scss";
import { Offer, OfferDAO, ItemSeller } from "../../../interfaces/OfferTypes";
import { useMetaMask } from "metamask-react";
import { useFetchEthUsdPrice } from "../../../queries/ethDollar";
import { encodeParams } from "../../../utils/signMessagesUtil";
import { convertWeiToETH } from "../../../utils/web3Utils";
import CancelOfferContainer from "../../tokensActions/cancelOfferContainer/cancelOfferContainer";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";

const OffersComponent: FC<OffersProps> = (props: OffersProps) => {
  const { collection, isOwner, token, onApproveOffer, tokenId } = props;
  const { account, chainId } = useMetaMask();
  const { data: ethUsdPrice } = useFetchEthUsdPrice();

  const [expanded, setExpanded] = useState(false);
  const headers = [
    "PRICE",
    "USD PRICE",
    "FLOOR DIFFERENCE",
    "EXPIRATION",
    "FROM",
  ];

  const handleOnApproveOffer = async (offerDAO: OfferDAO) => {
    if (account && chainId) {
      const offer: Offer = {
        buyer: offerDAO.buyer,
        collection: offerDAO.collection_id,
        tokenId: offerDAO.token_id,
        price: offerDAO.price.toString(),
        amount: offerDAO.amount,
        strategy: offerDAO.strategy,
        currency: offerDAO.currency,
        nonce: offerDAO.nonce,
        startTime: offerDAO.start_time,
        endTime: offerDAO.end_time,
        signature: offerDAO.signature,
        params: offerDAO.params,
      };
      const itemSeller: ItemSeller = {
        seller: account,
        price: offer.price.toString(),
        tokenId: tokenId,
        params: encodeParams([]),
      };

      await onApproveOffer(offer, itemSeller);
    }
  };

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="nft-listing-container"
      expanded={expanded}
      onChange={() => handleChange()}
    >
      <AccordionSummary
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "16px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
      >
        <img src="/offers.svg" alt="Image" style={{ marginRight: "1rem" }} />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>Offers</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontFamily: "LemonMilk-light",
          fontSize: "14px",
          padding: "0",
          marginTop: "0.5rem",
        }}
      >
        <TableContainer component={Paper} sx={{ background: "transparent" }}>
          <Table stickyHeader aria-label="collections stats">
            {token.offers && token.offers.length > 0 ? (
              <>
                <TableHead>
                  <TableRow>
                    {headers.map((header: string, i) => (
                      <TableCell>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {token.offers?.map((offer, i) => (
                    <TableRow key={i}>
                      <TableCell>
                        <div className="icon-price">
                          <img src="/smallEth.svg" alt="" />
                          <div className="offer-price">
                            {convertWeiToETH(offer.price)}
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {(
                          parseFloat(convertWeiToETH(offer.price)) *
                          parseInt(ethUsdPrice)
                        ).toFixed(2) + "$"}
                      </TableCell>
                      <TableCell>
                        {calcFloorDifference(
                          collection.floor_price.toString(),
                          offer.price
                        )}
                      </TableCell>
                      <TableCell>
                        {displayLeftAuctionTime(
                          offer.start_time,
                          offer.end_time
                        )}
                      </TableCell>

                      <TableCell>{offer.buyer.slice(0, 5) + "..."}</TableCell>
                      {isOwner && (
                        <TableCell>
                          <img
                            src="/cart.svg"
                            alt=""
                            style={{ cursor: "pointer" }}
                            onClick={async () =>
                              await handleOnApproveOffer(offer)
                            }
                          />
                        </TableCell>
                      )}
                      {checkOfferMaker(account, offer.buyer) && (
                        <TableCell>
                          <CancelOfferContainer
                            token={token}
                            collection={collection}
                            offerPrice={offer.price}
                            offerNonce={offer.nonce}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </>
            ) : (
              <EmptyState textData={"No offers yet"} />
            )}
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

interface OffersProps {
  collection: CollectionInterface;
  token: Token;
  isOwner: boolean;
  onApproveOffer: Function;
  tokenId: number;
}

export default OffersComponent;
