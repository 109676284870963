import { Button } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { get, post } from "../../../../fetch/fetchApi";
import MakeCollectionOfferDialog from "../makeCollectionOfferDialog/makeCollectionOfferDialog";
import { useMetaMask } from "metamask-react";
import { CollectionInterface } from "../../../../interfaces/collection";
import { Offer } from "../../../../interfaces/OfferTypes";
import { getOfferMsg } from "../../../../utils/signMessagesUtil";
import ConnectWallet from "../../../account/connectWallet";
import SwapContainer from "../../swapEthWeth/swapEthWethContainer";
import {
  getUserBalanceETH,
  getUserBalanceWETH,
  isApproveWETH,
  setApproveWETH,
} from "../../../../utils/web3Utils";
import MakeCollectionOfferStepsDialog from "../../makeCollectionOfferStepsDialog/makeOfferStepsDialog";
import MakeOfferStepsDialog from "../../makeCollectionOfferStepsDialog/makeOfferStepsDialog";
import GlobalDialog from "../../../globalDialog/globalDialog";

const MakeCollectionOfferContainer: FC<MakeCollectionOfferProps> = (
  props: MakeCollectionOfferProps
) => {
  const { collection } = props;
  const queryClient = useQueryClient();

  const [showWalletDialog, setShowWalletDialog] = useState<boolean>(false);
  const { account, chainId, connect, switchChain } = useMetaMask();
  const [userBalanceWETH, setUserBalanceWETH] = useState<string>("");
  const [userBalanceETH, setUserBalanceETH] = useState<string>("");
  const [showMakeOfferDialog, setShowMakeOfferDialog] =
    useState<boolean>(false);
  const [showMakeOfferStepsDialog, setShowMakeOfferStepsDialog] =
    useState<boolean>(false);
  const [showSwapEthWethDialog, setShowSwapEthWethDialog] =
    useState<boolean>(false);
  const [isApprovedWethSpending, setIsApprovedWethSpending] =
    useState<boolean>(false);
  const [isOrderApproved, setIsOrderApproved] = useState<boolean>(false);
  const [isConfirmOrder, setIsConfirmOrder] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const title = "Error";
  const content =
    "Error to preform collection offer, please check your wallet gas / balance";

  const [collectionOffer, setCollectionOffer] = useState<any>();
  const handleConnect = async () => {
    const connectResponse = await connect();
    if (connectResponse) {
      setShowWalletDialog(false);
    }
  };
  const { data: latestOfferNonce } = useQuery(
    ["latest-offer-nonce", `${account}/${collection.collection_id}`],
    () => get(`tokens/v1/latest-offer-nonce?buyer=${account}`),
    {
      enabled: !!(account && showMakeOfferDialog),
    }
  );

  useEffect(() => {
    if (!account) return;

    getUserBalanceWETH(account).then((data: string) => {
      setUserBalanceWETH(data.slice(0, 5));
    });
    getUserBalanceETH(account).then((data: string) => {
      setUserBalanceETH(data.slice(0, 5));
    });
  }, []);

  const sendCollectionOfferCreatorWithSigned = async (input: {
    collectionOffer: Offer;
  }): Promise<any> => {
    const { collectionOffer } = input;
    return await post("tokens/v1/add-offer", {
      buyer: collectionOffer.buyer,
      collection_id: collectionOffer.collection,
      token_id: collectionOffer.tokenId,
      price: collectionOffer.price,
      amount: collectionOffer.amount,
      strategy: collectionOffer.strategy,
      currency: collectionOffer.currency,
      nonce: latestOfferNonce,
      start_time: collectionOffer.startTime,
      end_time: collectionOffer.endTime,
      params: collectionOffer.params,
      signature: collectionOffer.signature,
    });
  };

  const { mutate: mutateCreateCollectionOffer } = useMutation(
    sendCollectionOfferCreatorWithSigned,
    {
      onSuccess: (data, variables) => {
        setIsOrderApproved(true);
        setCollectionOffer(variables.collectionOffer);

        queryClient.invalidateQueries([
          "get-token-by-collection-id",
          variables.collectionOffer.collection,
        ]);

        queryClient.invalidateQueries([
          "collections",
          variables.collectionOffer.collection,
        ]);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );
  const onMakeOfferDialog = (showMakeOfferDialog: boolean) => {
    if (account) {
      setShowMakeOfferDialog(showMakeOfferDialog);
    } else {
      setShowWalletDialog(true);
    }
  };

  const onMakeCollectionOffer = async (collectionOffer: Offer) => {
    if (collectionOffer.buyer?.toLowerCase() !== account?.toLowerCase()) {
      //TODO: navigate to login / render dialog of login first
      return;
    }
    if (chainId !== process.env.REACT_APP_CHAIN_ID_PROD) {
      await switchChain("0x1");
      return;
    }
    try {
      setShowMakeOfferDialog(false);
      setShowMakeOfferStepsDialog(true);
      setCollectionOffer(collectionOffer);

      const allowance = await isApproveWETH(account, collectionOffer.price);

      const approve = allowance
        ? true
        : await setApproveWETH(account, collectionOffer.price);
      if (approve) {
        setIsApprovedWethSpending(true);

        const typedData = getOfferMsg(collectionOffer, chainId);

        collectionOffer.signature = await window.ethereum.request({
          method: "eth_signTypedData_v4",
          params: [account, JSON.stringify(typedData)],
        });
        setIsConfirmOrder(true);

        mutateCreateCollectionOffer({ collectionOffer });
      }
    } catch (err) {
      setCollectionOffer(undefined);
      setIsApprovedWethSpending(false);
      setIsConfirmOrder(false);
      setShowMakeOfferStepsDialog(false);
      setError(true);
      //TODO: user is not approved signed method
      //https://github.com/venus-ci/venus-client-react/issues/15
    }
  };

  return (
    <>
      <Button className="box" onClick={() => onMakeOfferDialog(true)}>
        Make Collection Offer
      </Button>
      {showMakeOfferDialog && collection && (
        <MakeCollectionOfferDialog
          setShowSwapEthWethDialog={setShowSwapEthWethDialog}
          makeCollectionOffer={onMakeCollectionOffer}
          collection={collection}
          setShowMakeOfferDialog={setShowMakeOfferDialog}
          open={showMakeOfferDialog}
          nonce={latestOfferNonce}
          userBalanceWETH={userBalanceWETH}
          userBalanceETH={userBalanceETH}
        />
      )}
      {showMakeOfferStepsDialog && collectionOffer && (
        <MakeOfferStepsDialog
          collection={collection}
          offer={collectionOffer}
          setShowMakeOfferStepsDialog={setShowMakeOfferStepsDialog}
          open={showMakeOfferStepsDialog}
          isApprovedWethSpending={isApprovedWethSpending}
          setIsApprovedWethSpending={setIsApprovedWethSpending}
          isConfirmOrder={isConfirmOrder}
          setIsConfirmOrder={setIsConfirmOrder}
          isOrderApproved={isOrderApproved}
          isCollectionOffer
        />
      )}
      {showSwapEthWethDialog && (
        <SwapContainer
          setShowDwapDialog={setShowSwapEthWethDialog}
          showDialog={showSwapEthWethDialog}
        />
      )}
      {showWalletDialog && (
        <ConnectWallet
          connect={handleConnect}
          open={showWalletDialog}
          setShowWalletDialog={setShowWalletDialog}
        />
      )}
      {error && (
        <GlobalDialog
          isOpen={error}
          setIsOpen={setError}
          title={title}
          content={content}
        />
      )}
    </>
  );
};
interface MakeCollectionOfferProps {
  collection: CollectionInterface;
}
export default MakeCollectionOfferContainer;
