import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../fetch/fetchApi";
import { ListingTable } from "../../interfaces/ListingTypes";
import { convertWeiToETH } from "../../utils/web3Utils";
import { ListingTableComponent } from "./listingTable";

const ListingTableContainer: FC<{
  account: string;
}> = ({ account }: { account: string }) => {
  const [listings, setListings] = useState<ListingTable[]>([]);
  const { data, isLoading } = useQuery(
    ["get-listings", `${account}`],
    () => get(`get-listings?wallet_address=${account}`),
    {
      enabled: !!account,
    }
  );
  useEffect(() => {
    if (data) {
      const tempListings: ListingTable[] = [];
      data.map((item: any) => {
        tempListings.push({
          token_id: item.token_id,
          image_url: item.image_url,
          price: convertWeiToETH(item.price),
          floor_price: item.floor_price,
          start_time: item.start_time,
          collection_name: item.name,
          end_time: item.end_time,
          sales: item.sales,
          metadata: item.metadata,
        });
      });
      setListings(tempListings);
    }
  }, [data]);

  return <ListingTableComponent listings={listings} isLoading={isLoading} />;
};

export default ListingTableContainer;
