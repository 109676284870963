import { Listing } from "../interfaces/ListingTypes";
import { Offer } from "../interfaces/OfferTypes";
import { defaultAbiCoder } from "ethers/lib/utils";
import { BigNumber, utils } from "ethers";
import {addressesByNetwork} from "../constants/addresses";

export const encodeParams = (params: any[]) => {
  const nonNullParams = params || [];
  const paramsTypes = nonNullParams.map((param) => {
    if (utils.isAddress(param)) {
      return "address";
    }

    if (typeof param === "boolean") {
      return "bool";
    }

    try {
      BigNumber.from(param);
      return "uint256";
    } catch (e) {
      throw Error("Invalid param type");
    }
  });
  return defaultAbiCoder.encode(paramsTypes, nonNullParams);
};

export const getOfferMsg = (offer: Offer, chainId: string) => {
  return {
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Offer: [
        { name: "buyer", type: "address" },
        { name: "collection", type: "address" },
        { name: "tokenId", type: "uint256" },
        { name: "price", type: "uint256" },
        { name: "amount", type: "uint256" },
        { name: "strategy", type: "address" },
        { name: "currency", type: "address" },
        { name: "nonce", type: "uint256" },
        { name: "startTime", type: "uint256" },
        { name: "endTime", type: "uint256" },
        { name: "params", type: "bytes" },
      ],
    },
    primaryType: "Offer",
    domain: {
      name: "VenusMarketplace",
      version: "1",
      chainId: chainId,
      verifyingContract: addressesByNetwork(chainId).VENUS_MARKETPLACE,
    },
    message: {
      buyer: offer.buyer,
      collection: offer.collection,
      tokenId: offer.tokenId,
      price: offer.price,
      amount: offer.amount,
      strategy: offer.strategy,
      currency: offer.currency,
      nonce: offer.nonce,
      startTime: offer.startTime,
      endTime: offer.endTime,
      params: offer.params,
    },
  };
};

export const getListingMsg = (listing: Listing, chainId: string) => {
  return {
    types: {
      EIP712Domain: [
        { name: "name", type: "string" },
        { name: "version", type: "string" },
        { name: "chainId", type: "uint256" },
        { name: "verifyingContract", type: "address" },
      ],
      Listing: [
        { name: "seller", type: "address" },
        { name: "collection", type: "address" },
        { name: "tokenId", type: "uint256" },
        { name: "price", type: "uint256" },
        { name: "amount", type: "uint256" },
        { name: "strategy", type: "address" },
        { name: "currency", type: "address" },
        { name: "nonce", type: "uint256" },
        { name: "startTime", type: "uint256" },
        { name: "endTime", type: "uint256" },
        { name: "params", type: "bytes" },
      ],
    },
    primaryType: "Listing",
    domain: {
      name: "VenusMarketplace",
      version: "1",
      chainId: chainId,
      verifyingContract: addressesByNetwork(chainId).VENUS_MARKETPLACE,
    },
    message: {
      seller: listing.seller,
      collection: listing.collection,
      tokenId: listing.tokenId,
      price: listing.price,
      amount: listing.amount,
      strategy: listing.strategy,
      currency: listing.currency,
      nonce: listing.nonce,
      startTime: listing.startTime,
      endTime: listing.endTime,
      params: listing.params,
    },
  };
};
