import * as React from "react";
import { FC } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import "./timePicker.scss";
import Button from "@mui/material/Button";

interface TimePickerProps {
  setTimePicked: Function;
}

export const TimePicker: FC<TimePickerProps> = (props: TimePickerProps) => {
  const { setTimePicked } = props;

  return (
    <div className="timer-picker-wrapper">
      <Box
        sx={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
          width: "fit-content",
          border: (theme) => `2px solid ${theme.palette.divider}`,
          borderRadius: 10,
          color: "white",
          borderColor: "#901662",
          boxShadow: "0 0 12px 0 rgba(157, 96, 212, 0.5) !important",
          opacity: 0.8,
        }}
      >
        <Button
          onClick={() => setTimePicked("24h")}
          sx={{ borderRadius: "25px 0 0 25px !important" }}
          className="time-picker-button"
        >
          24H
        </Button>
        <Divider
          sx={{ borderColor: "#901662" }}
          orientation="vertical"
          flexItem
        />
        <Button
          onClick={() => setTimePicked("7d")}
          className="time-picker-button"
        >
          7D
        </Button>
        <Divider
          sx={{ borderColor: "#901662" }}
          orientation="vertical"
          flexItem
        />
        <Button
          onClick={() => setTimePicked("30d")}
          className="time-picker-button"
        >
          30D
        </Button>
        <Divider
          sx={{ borderColor: "#901662" }}
          orientation="vertical"
          flexItem
        />
        <Button
          onClick={() => setTimePicked("all")}
          sx={{ borderRadius: "0 25px 25px 0 !important" }}
          className="time-picker-button"
        >
          ALL
        </Button>
      </Box>
    </div>
  );
};
