import { Button } from "@mui/material";
import { FC, useState } from "react";
import { Token } from "../../../interfaces/token";
import VenusMarketPlace from "../../../contracts/marketplace.json";
import { AbiItem } from "web3-utils";
import { CollectionInterface } from "../../../interfaces/collection";
import Web3 from "web3";
import { useQueryClient } from "react-query";
import GlobalDialog from "../../globalDialog/globalDialog";
import CompletePurcahseDialog from "../../tokenDetailsPage/completePurcahseDialog/completePurcahseDialog";
import { ItemBuyer } from "../../../interfaces/ListingTypes";
import { addressesByNetwork } from "../../../constants/addresses";
import { useMetaMask } from "metamask-react";
import { encodeParams } from "../../../utils/signMessagesUtil";
import BuyDialog from "./buyDialog";

const BuyTokenContainer: FC<BuyTokenContainerProps> = (
  props: BuyTokenContainerProps
) => {
  const { token, collection } = props;
  const { account, chainId } = useMetaMask();
  const queryClient = useQueryClient();
  const [showCompletePurcahseDialog, setShowCompletePurcahseDialog] =
    useState<boolean>(false);
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);
  const [buyDialog, setBuyDialog] = useState<boolean>(false);

  const onRefreshPage = async (token: Token) => {
    await queryClient.invalidateQueries([
      "get-token-by-collection-id",
      token.collection_id.toLowerCase(),
      token.token_id,
    ]);
    setShowCompletePurcahseDialog(false);
  };

  const buyToken = async (token: Token) => {
    const web3 = new Web3(window.ethereum);
    setBuyDialog(true);
    if (token.listing && account && chainId) {
      const listing = {
        seller: token.listing.seller,
        collection: token.listing.collection_id,
        tokenId: token.listing.token_id,
        price: token.listing.price.toString(),
        amount: token.listing.amount,
        strategy: token.listing.strategy,
        currency: token.listing.currency,
        nonce: token.listing.nonce,
        startTime: token.listing.start_time,
        endTime: token.listing.end_time,
        params: token.listing.params,
        signature: token.listing.signature,
      };
      const itemBuyer: ItemBuyer = {
        buyer: account,
        price: listing.price.toString(),
        tokenId: listing.tokenId,
        params: encodeParams([]),
      };
      try {
        const venusMarketPlaceContract = new web3.eth.Contract(
          VenusMarketPlace.abi as AbiItem[],
          addressesByNetwork(chainId).VENUS_MARKETPLACE
        );

        const buyNowEstGas = await venusMarketPlaceContract.methods
          .buyWithETH(listing, itemBuyer)
          .estimateGas({
            from: account,
            value: token.listing.price,
          });

        const functionData = venusMarketPlaceContract.methods
          .buyWithETH(listing, itemBuyer)
          .encodeABI();
        const approveOffer = {
          to: addressesByNetwork(chainId).VENUS_MARKETPLACE,
          data: functionData,
          gas: buyNowEstGas,
          from: account,
          value: token.listing.price,
        };
        const signedTx = await web3.eth.sendTransaction(approveOffer);
        if (signedTx) {
          setBuyDialog(false);
          setShowCompletePurcahseDialog(true);
        }
      } catch (err) {
        console.log(err);
        setErrorMetaMask(true);
        setTitle("Error Metamask");
        setContent(
          "Please verify you are on the mainnet, and wallet is unlocked"
        );
      }
    }
  };
  return (
    <>
      <Button className="buy-now" onClick={async () => buyToken(token)}>
        Buy now
      </Button>
      {showCompletePurcahseDialog && (
        <CompletePurcahseDialog
          token={token}
          collection={collection}
          setShowCompletePurcahseDialog={setShowCompletePurcahseDialog}
          open={showCompletePurcahseDialog}
          refreshPage={onRefreshPage}
        />
      )}
      {buyDialog && (
        <BuyDialog
          token={token}
          setBuyDialog={setBuyDialog}
          open={buyDialog}
          collection={collection}
        />
      )}
      {errorMetaMask ? (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      ) : undefined}
    </>
  );
};
interface BuyTokenContainerProps {
  token: Token;
  collection: CollectionInterface;
}
export default BuyTokenContainer;
