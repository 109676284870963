import { FC, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./makeOfferDialog.scss";
import { CollectionInterface } from "../../../../interfaces/collection";
import {
  Button,
  DialogActions,
  InputBase,
  InputAdornment,
  Divider,
  FormHelperText,
} from "@mui/material";
import { useMetaMask } from "metamask-react";
import {
  convertETHtoWei,
  convertWeiToETH,
  getUserBalanceETH,
} from "../../../../utils/web3Utils";
import CustomDurationPicker from "../../../duration/duration";
import dayjs, { Dayjs } from "dayjs";
import { handleImageCard, handleTokenName } from "../../../../utils/util";
import { Token } from "../../../../interfaces/token";
import { useFetchEthUsdPrice } from "../../../../queries/ethDollar";
import { Offer } from "../../../../interfaces/OfferTypes";
import { addressesByNetwork } from "../../../../constants/addresses";
import { encodeParams } from "../../../../utils/signMessagesUtil";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const MakeOfferDialog: FC<MakeOfferProps> = (props: MakeOfferProps) => {
  const {
    token,
    setShowMakeOfferDialog,
    open,
    collection,
    makeOffer,
    nonce,
    setShowSwapEthWethDialog,
    userBalanceWETH,
  } = props;
  const [wethPrice, setWethPrice] = useState<number>(0);
  const { account, chainId } = useMetaMask();
  const [error, setError] = useState(false);
  const [endDate, setEndDate] = useState<Dayjs>(dayjs().add(1, "day")); // set initial value to tomorrow's date
  const { data: ethUsdPrice } = useFetchEthUsdPrice();
  const [disableButton, setDisableButton] = useState<boolean>(true);

  const handleInputChange = (event: any) => {
    const newValue = Number(event.target.value);

    if (0 < newValue && newValue <= Number(userBalanceWETH)) {
      setError(false);
      setDisableButton(false);
    } else {
      setError(true);
      setDisableButton(true);
    }
    setWethPrice(event.target.value);
  };

  const handleClickOpen = () => {
    setShowMakeOfferDialog(true);
  };
  const handleClose = () => {
    setShowMakeOfferDialog(false);
  };

  const handleOnMakeOffer = async (
    buyer: string | null,
    token: Token,
    price: string,
    endTime: number
  ) => {
    //No-buyer logged in
    if (!buyer || !chainId) {
      return;
    }
    //validation failed.
    if (error) {
      return;
    }
    const date = new Date(endTime);
    const utcDate = new Date(date.toUTCString());
    const offer: Offer = {
      buyer: buyer,
      collection: token.collection_id,
      tokenId: token.token_id,
      price: price,
      amount: 1,
      strategy: addressesByNetwork(chainId).STRATEGY_STANDARD_SALE_SINGLE_ITEM,
      currency: addressesByNetwork(chainId).WETH,
      nonce: nonce,
      startTime: Math.round(new Date().getTime() / 1000),
      endTime: Math.round(utcDate.getTime() / 1000),
      params: encodeParams([]),
      signature: "",
    };
    await makeOffer(offer);

    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Make Offer
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="offer-container">
            <div className="offer-name-details-wrapper">
              <div className="offer-nft-name-icon-wrapper">
                <div className="offer-nft-icon">
                  <img src={handleImageCard(token)} width="100" height="100" />
                </div>
                <div className="nft-name-collection-wrapper">
                  <div className="offer-nft-name">{handleTokenName(token)}</div>
                  <div className="offer-nft-collection">{collection.name}</div>
                </div>
              </div>
              <div className="gray-small-font">
                <div className="offer-nft-price-eth">
                  floor price: {convertWeiToETH(collection.floor_price)}
                </div>
                <div className="offer-nft-price-eth">
                  best offer:{convertWeiToETH(collection.best_offer.toString())}
                </div>
              </div>
            </div>

            <div className="offer-set-price">
              <div className="duration-const">Set Price</div>
              <div className="gray-small-font">
                WETH Balance: {userBalanceWETH}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "1rem",
                }}
              >
                <div className="offer-price">
                  <InputBase
                    sx={{
                      width: "100%",
                      fontFamily: "LemonMilk",
                      marginLeft: "1rem",
                    }}
                    placeholder="Price"
                    value={wethPrice}
                    error={error}
                    onChange={handleInputChange}
                    endAdornment={
                      <InputAdornment
                        sx={{ color: "white", marginRight: "1.5rem" }}
                        position="end"
                      >
                        <div>WETH</div>
                      </InputAdornment>
                    }
                  />
                </div>

                <Button
                  variant="text"
                  className="swap-eth-weth"
                  onClick={() => setShowSwapEthWethDialog(true)}
                >
                  <div className="swap-text">Swap ETH/WETH</div>
                </Button>
              </div>
              {error && (
                <FormHelperText error>
                  The amount cannot be higher than the wallet balance.
                </FormHelperText>
              )}
            </div>

            <div className="offer-duration">
              <div className="duration-const">Set Duration</div>
              <div className="duration-value">
                <CustomDurationPicker setDate={setEndDate} date={endDate} />
              </div>
            </div>
            <Divider light className="divider" />
            <div className="offer-summary">
              <div className="duration-const">SUMMARY</div>
              <div className="const-text">Fees: 0 </div>
              {wethPrice > 0 && (
                <div className="const-text">
                  Possible earn: {wethPrice} /{" "}
                  {(ethUsdPrice * wethPrice).toFixed(2)}$
                </div>
              )}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={disableButton ? "gradient-btn-disabled" : "gradient-btn"}
            autoFocus
            disabled={disableButton}
            sx={{ marginBottom: "1rem" }}
            onClick={async () =>
              await handleOnMakeOffer(
                account,
                token,
                convertETHtoWei(wethPrice.toString()),
                endDate.valueOf()
              )
            }
          >
            Make offer
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface MakeOfferProps {
  token: Token;
  setShowMakeOfferDialog: Function;
  setShowSwapEthWethDialog: Function;
  makeOffer: Function;
  open: boolean;
  collection: CollectionInterface;
  nonce: number;
  userBalanceWETH: string;
  userBalanceETH: string;
}
export default MakeOfferDialog;
