import Button from "@mui/material/Button";
import { FC } from "react";
import { CollectionInterface } from "../../../interfaces/collection";
import { useNavigate } from "react-router-dom";
import { Token } from "../../../interfaces/token";
import MakeTokenOfferContainer from "../offerAction/makeOfferContainer/makeOfferContainer";
import { checkOwner, displayLeftAuctionTime } from "../../../utils/util";
import { useMetaMask } from "metamask-react";
import ListingTokenContainer from "../listingTokenContainer/listingTokenContainer";
import BuyTokenContainer from "../buyTokenContainer/buyTokenContainer";
import "./tokenAuctionDetails.scss";
import CancelListingContainer from "../cancelListingContainer/cancelListingContainer";
import TransferTokenContainer from "../transferTokenContainer/transferTokenContainer";
import { convertWeiToETH } from "../../../utils/web3Utils";

const TokenAuctionDetails: FC<TokenAuctionDetailsProps> = (
  props: TokenAuctionDetailsProps
) => {
  const { token, collection, ethUsdPrice } = props;
  const { account } = useMetaMask();
  const isOwner = checkOwner(account, token.owner);

  return (
    <div className="nft-auction-details-container">
      {token.listing && (
        <div className="current-price-wrapper">
          <div className="current-price-const">Current Price</div>
          <div className="current-price-eth-wrapper">
            <div className="current-price-eth-icon">
              <img src="/eth.svg" alt="" />
            </div>
            <div className="current-price-eth">
              {convertWeiToETH(token.listing.price)}
            </div>
            <div className="current-price-usd">
              {(
                parseFloat(convertWeiToETH(token.listing.price)) *
                parseInt(ethUsdPrice)
              ).toFixed(2) + "$"}
            </div>
          </div>
          <div className="time-left-wrapper">
            <div className="current-price-const">Expiration</div>
            <div className="time-left-value">
              {displayLeftAuctionTime(
                token.listing.start_time,
                token.listing.end_time
              )}
            </div>
          </div>
        </div>
      )}
      <div className="actions-buttons">
        {isOwner && (
          <>
            {!token.listing && (
              <ListingTokenContainer token={token} collection={collection} />
            )}
            <TransferTokenContainer token={token} collection={collection} />
          </>
        )}
        {token.listing && !isOwner && (
          <BuyTokenContainer token={token} collection={collection} />
        )}
        {!isOwner ? (
          <MakeTokenOfferContainer collection={collection} token={token} />
        ) : token.listing ? (
          <CancelListingContainer token={token} collection={collection} />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

interface TokenAuctionDetailsProps {
  token: Token;
  collection: CollectionInterface;
  ethUsdPrice: string;
}

export default TokenAuctionDetails;
