import { Button } from "@mui/material";
import { FC, useState } from "react";
import { Token } from "../../../interfaces/token";
import VenusMarketPlace from "../../../contracts/marketplace.json";
import { AbiItem } from "web3-utils";
import Web3 from "web3";
import { useMetaMask } from "metamask-react";
import { addressesByNetwork } from "../../../constants/addresses";
import { useQueryClient } from "react-query";
import CancelOfferDialog from "./cancelOfferDialog";
import { Offer, OfferDAO } from "../../../interfaces/OfferTypes";
import { CollectionInterface } from "../../../interfaces/collection";

const CancelOfferContainer: FC<CancelOfferContainerProps> = (
  props: CancelOfferContainerProps
) => {
  const { token, collection, offerPrice, offerNonce } = props;
  const queryClient = useQueryClient();
  const [cancelOfferDialog, setCancelOfferDialog] = useState<boolean>(false);
  const { account, chainId } = useMetaMask();

  const cancelOffer = async () => {
    const web3 = new Web3(window.ethereum);
    if (offerNonce && account) {
      setCancelOfferDialog(true);
      let signedTx;
      try {
        const venusMarketPlaceContract = new web3.eth.Contract(
          VenusMarketPlace.abi as AbiItem[],
          addressesByNetwork(chainId).VENUS_MARKETPLACE
        );

        const cancelOfferGas = await venusMarketPlaceContract.methods
          .cancelMultipleOffersForSender([offerNonce])
          .estimateGas({
            from: account,
          });

        const functionData = venusMarketPlaceContract.methods
          .cancelMultipleOffersForSender([offerNonce])
          .encodeABI();
        const cancelledOffer = {
          to: addressesByNetwork(chainId).VENUS_MARKETPLACE,
          data: functionData,
          gas: cancelOfferGas,
          from: account,
        };
        signedTx = await web3.eth.sendTransaction(cancelledOffer);
        if (signedTx) {
          queryClient.invalidateQueries([
            "get-token-by-collection-id",
            collection.collection_id.toLowerCase(),
            token.token_id,
          ]);
          setCancelOfferDialog(false);
        }
      } catch (err) {
        // setErrorMetaMask(true);
        // setTitle("Error Metamask");
        // setContent(
        //   "Please verify you are on the mainnet, and wallet is unlocked"
        // );
        setCancelOfferDialog(false);
      }
    }
  };
  return (
    <>
      <img src="/cancel.svg" alt="" onClick={async () => await cancelOffer()} />
      {cancelOfferDialog && (
        <CancelOfferDialog
          token={token}
          collection={collection}
          offerPrice={offerPrice}
          setCancelOfferDialog={setCancelOfferDialog}
          open={cancelOfferDialog}
        />
      )}
    </>
  );
};
interface CancelOfferContainerProps {
  token: Token;
  collection: CollectionInterface;
  offerPrice: string;
  offerNonce: number;
}
export default CancelOfferContainer;
