import { FC, useEffect, useMemo, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { post } from "../../fetch/fetchApi";
import CollectionHeader from "./collectionHeader";
import { CollectionInterface } from "../../interfaces/collection";
import { useMetaMask } from "metamask-react";
import GlobalDialog from "../globalDialog/globalDialog";
import { useIsWatchedByWallet } from "../../queries/collections";

const CollectionHeaderContainer: FC<CollectionHeaderContainerProps> = (
  props: CollectionHeaderContainerProps
) => {
  const { collectionHeader, setShowRefreshDialog } = props;
  const { account } = useMetaMask();
  const queryClient = useQueryClient();
  const [errorMetaMask, setErrorMetaMask] = useState<boolean>(false);
  const [isWatched, setIsWatched] = useState<boolean>(false);

  const title = "Error";
  const content = "We couldnt make the refresh, please try again";

  const { data } = useIsWatchedByWallet(collectionHeader, account!);

  useEffect(() => {
    if (!data) return;

    setIsWatched(data);
  }, [data]);

  const refreshCollectionCall = async (input: {
    collectionAddress: string;
  }): Promise<any> => {
    const { collectionAddress } = input;
    const response = await post(
      `refresh-collection?contract_address=${collectionAddress}`,
      {}
    );
    return response;
  };

  const { mutate: mutateRefreshCollection } = useMutation(
    refreshCollectionCall,
    {
      onSuccess: (data, variables) => {
        setShowRefreshDialog(true);
        queryClient.invalidateQueries([
          "collection",
          `collection/${variables.collectionAddress}/nfts`,
        ]);
      },
      onError: (error) => {},
    }
  );

  const onRefreshCollection = async (collectionAddress: string) => {
    mutateRefreshCollection({ collectionAddress });
  };

  const watchCollection = async (input: {
    collectionAddress: string;
    account: string;
  }): Promise<any> => {
    const { collectionAddress, account } = input;
    const response = await post(
      `collection/v1/${collectionAddress}/watch/${account}`,
      {
        collection_id: collectionAddress,
        wallet_address: account,
      }
    );
    return response;
  };

  const { mutate: mutateWatchCollection } = useMutation(watchCollection, {
    onSuccess: (data, variables) => {
      setIsWatched(true);
      queryClient.invalidateQueries([
        "collection",
        `/collections/v1/${variables.account}/watch-list`,
      ]);
    },
    onError: (error) => {},
  });

  const onWatchCollection = async (collectionAddress: string) => {
    try {
      if (account) {
        mutateWatchCollection({ collectionAddress, account });
      }
    } catch (err) {
      setErrorMetaMask(true);
    }
  };

  return (
    <>
      <CollectionHeader
        collection={collectionHeader}
        refreshCollection={onRefreshCollection}
        watchCollection={onWatchCollection}
        isWatchedByMe={isWatched}
      />
      {errorMetaMask && (
        <GlobalDialog
          isOpen={errorMetaMask}
          setIsOpen={setErrorMetaMask}
          title={title}
          content={content}
        />
      )}
    </>
  );
};
interface CollectionHeaderContainerProps {
  collectionHeader: CollectionInterface;
  setShowRefreshDialog: Function;
}
export default CollectionHeaderContainer;
