import { useQuery } from "react-query";
import { get } from "../fetch/fetchApi";

export const search = "search";

export const useSearchTokens = (keyword: string, collection_id: string) => {
  return useQuery(
    [search, keyword],
    () => get(`tokens/v1/${collection_id}?name=${keyword}`),
    {
      enabled: !!keyword,
    }
  );
};
