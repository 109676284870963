import { FC } from "react";
import InnerRoutes from "./routes/routes";
import "./App.css";
import "./fonts/fonts.scss";

const App: FC = () => {
  return <InnerRoutes />;
};

export default App;
