import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableCell, Tooltip } from "@mui/material";
import { FC } from "react";
import Button from "@mui/material/Button";
import { OfferTable } from "../../interfaces/OfferTypes";
import Loader from "../loader/loader";
import { useFetchEthUsdPrice } from "../../queries/ethDollar";
import { displayLeftAuctionTime } from "../../utils/util";
import EmptyState from "../emptyState/emptyState";
import { useNavigate } from "react-router-dom";

export const OffersTable: FC<OffersTableProps> = (props: OffersTableProps) => {
  const { isLoading, offers } = props;
  const { data: ethUsdPrice } = useFetchEthUsdPrice();
  const navigate = useNavigate();

  const onShowMoreClicked = () => {};

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <TableContainer
        component={Paper}
        sx={{ background: "transparent", marginTop: "1.5rem" }}
      >
        <Table stickyHeader aria-label="collections stats">
          {offers && offers.length > 0 ? (
            <>
              <TableHead>
                <TableRow>
                  <TableCell>COLLECTION</TableCell>
                  <TableCell>SALES</TableCell>
                  <TableCell>FlOOR PRICE</TableCell>
                  <TableCell>Your offer</TableCell>
                  <TableCell>Expiration</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {offers.map((offer, i) => (
                  <TableRow key={i}>
                    <TableCell
                      className="collection-image-cell"
                      onClick={() =>
                        navigate(`/collection/${offer.collection_id}`)
                      }
                    >
                      {offer.metadata ? (
                        <>
                          <img
                            className="collection-image"
                            src={
                              offer.metadata.image
                                ? offer.metadata.image
                                : "/noContent.svg"
                            }
                            alt=""
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div>{offer.metadata.name}</div>
                            <div
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              {offer.collection_name}
                            </div>
                          </div>
                        </>
                      ) : offer.token_id == 0 ? (
                        <>
                          <img
                            className="collection-image"
                            src={
                              offer.image_url
                                ? offer.image_url
                                : "/noContent.svg"
                            }
                            alt=""
                          />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{ fontSize: "12px", fontWeight: "400" }}
                            >
                              {offer.collection_name}
                            </div>
                          </div>
                        </>
                      ) : (
                        <div>
                          {offer.collection_name}-{offer.token_id}
                        </div>
                      )}
                      <Tooltip title={offer.collection_name} placement="top">
                        <div></div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{offer.sales ? offer.sales : 0}</TableCell>
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <div>{offer.price}</div>
                        <img src="/eth.svg" alt="" />
                      </div>
                    </TableCell>
                    <TableCell>
                      <div>{offer.price} ETH</div>
                      <div className="grey-font-usd">
                        {(
                          parseFloat(offer.price) * parseInt(ethUsdPrice)
                        ).toFixed(2) + "$"}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="grey-font-usd">
                        {displayLeftAuctionTime(
                          offer.start_time,
                          offer.end_time
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          ) : (
            <EmptyState textData={"No offers yet"} />
          )}
        </Table>
      </TableContainer>
      {/* <div style={{ textAlign: "center", marginTop: 20 }}>
        <Button onClick={onShowMoreClicked} className="show-more-gradient-btn">
          Show More
        </Button>
      </div> */}
    </>
  );
};
interface OffersTableProps {
  offers: OfferTable[];
  isLoading: boolean;
}
