import { BigNumber } from "ethers";

export interface ItemActivityUI {
  event: ItemActivityTypes;
  price?: string;
  priceUsd?: string;
  from: string;
  to: string;
  date: Date;
  collection_id: string;
}

export interface ItemActivityRequest {
  event: ItemActivityTypes;
  price?: string;
  from: string;
  to: string;
  collectionId: string;
  tokenId: number;
}

export enum ItemActivityTypes {
  Trasnfer = "Transfer",
  Sale = "Sale",
  Listing = "Listing",
  Offer = "Offer",
}
