import { FC, useCallback, useRef, useState } from "react";
import { useMetaMask } from "metamask-react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useNavigate } from "react-router-dom";
import { Action, ActionType } from "../../interfaces/nftActions";
import Loader from "../loader/loader";
import OffersContainer from "../offersTable/offersContainer";
import TokenCard from "../tokenCard/tokenCard";
import { Token } from "../../interfaces/token";
import MyCollectionsContainer from "../myCollections/myCollectionsContainer";
import ListingTableContainer from "../listingTable/listingTableContainer";
import "./user.scss";
import EmptyState from "../emptyState/emptyState";

const User: FC<UserProps> = (props: UserProps) => {
  const { tokens, isLoading, account, isLoadingData, onLoadMore, hasMore } =
    props;
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const observer = useRef<IntersectionObserver | null>(null);
  const lastRowElementRef = useCallback(
    (node: any) => {
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          !isLoading && hasMore && onLoadMore?.();
        }
      });
      if (node) observer.current.observe(node);
    },
    [hasMore, onLoadMore]
  );
  return (
    <div className="container">
      <div className="user-name" style={{ marginBottom: "2rem" }}>
        {account}
      </div>
      <div className="user-logo"></div>
      <Tabs className="tabs" value={value} onChange={handleChange}>
        <Tab label="Owned" />
        <Tab label="Listings" />
        <Tab label="Offers" />
        <Tab label="My Collections" />
      </Tabs>
      {value === 0 && (
        <div className="nft-list-owned">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            {(isLoading || isLoadingData) && tokens.length === 0 ? (
              <Loader />
            ) : (
              <>
                {tokens?.map((token: any, i) => (
                  <TokenCard
                    refFunction={
                      tokens.length === i + 1 ? lastRowElementRef : undefined
                    }
                    token={token}
                    key={i}
                    onClick={() =>
                      navigate(
                        `/collection/${token.collection_id}/token/${token.token_id}`
                      )
                    }
                  />
                ))}
                {tokens.length == 0 && (
                  <EmptyState textData={"No tokens yet"} />
                )}
              </>
            )}
          </div>
        </div>
      )}
      {value === 1 && <ListingTableContainer account={account} />}
      {value === 2 && <OffersContainer account={account} />}
      {value === 3 && <MyCollectionsContainer />}
    </div>
  );
};

interface UserProps {
  tokens: Token[];
  isLoading: boolean;
  isLoadingData: boolean;
  account: string;
  onLoadMore: Function;
  hasMore: boolean;
}

export default User;
