import Accordion from "@mui/material/Accordion";
import { FC, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import { CollectionInterface } from "../../../interfaces/collection";
import { getShortAddress } from "../../../utils/util";
import "./details.scss";
import { Token } from "../../../interfaces/token";

const Details: FC<DetailsProps> = (props: DetailsProps) => {
  const { collection, token } = props;
  const [expanded, setExpanded] = useState(false);

  const attributes = [
    "CONTRACT ADDRESS",
    "TOKEN ID",
    "TOKEN STANDARD",
    "CHAIN",
    // "CREATED AT",
  ];
  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <Accordion
      className="nft-details-container"
      expanded={expanded}
      onChange={() => handleChange()}
      square
    >
      <AccordionSummary
        sx={{
          borderBottom: "1px solid rgba(218, 50, 154, 0.2)",
          borderRadius: "24px",
        }}
        expandIcon={<ExpandMoreIcon sx={{ color: "white" }} />}
      >
        <img
          src="/details.svg"
          alt="details-img"
          style={{ marginRight: "1rem" }}
        />
        <Typography sx={{ fontFamily: "LemonMilk-light" }}>Details</Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          fontFamily: "LemonMilk-light",
          fontSize: "14px",
          padding: "0",
          marginTop: "0.5rem",
        }}
      >
        <div className="nft-details">
          <div className="nft-details-headers">
            {attributes.map((header: string, i) => (
              <div className="nft-details-header" key={i}>
                {header}
              </div>
            ))}
          </div>
          <div className="nft-details-data">
            <a
              className="details-contract-address"
              href={`https://etherscan.io/address/${collection.collection_id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {getShortAddress(collection?.collection_id)}
            </a>
            <div className="details-tokenid">
              {token.token_id.toString().slice(0, 10)}
            </div>
            <div className="details-erc">{collection?.token_type}</div>
            <div className="details-chain">ETHEREUM</div>
            {/* <div className="details-chain">{token.created_at}</div> */}
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

interface DetailsProps {
  collection: CollectionInterface;
  token: Token;
}

export default Details;
