import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { CollectionInterface } from "../../interfaces/collection";
import { getShortenCollectionName } from "../../utils/util";
import { convertWeiToETH } from "../../utils/web3Utils";
import "./topCollection.scss";

const TopCollection: FC<TopCollectionProps> = (props: TopCollectionProps) => {
  const { collection } = props;
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/collection/${collection.collection_id}`);
  };
  return (
    <div className="collection-wrapper" onClick={handleClick}>
      <div className="icon">
        <img
          src={collection.image_url ? collection.image_url : "/Venus-logo.svg"}
          alt=""
          className="small-image"
        />
      </div>
      <div className="name-floor-price-wrapper">
        <div className="name">
          {getShortenCollectionName(collection.name, 20)}
        </div>
        <div className="floor-price">
          Floor price: <img alt="" src="/eth.svg" width="15px" height="15px" />
          {collection.floor_price
            ? convertWeiToETH(collection.floor_price)
            : "--"}
        </div>
      </div>
      <div className="volume-icon">
        <div className="eth-icon">
          <img alt="" src="/eth.svg" width="18px" height="18px" />
        </div>
        <div className="volume">
          vol: {collection.volume ? convertWeiToETH(collection.volume) : 0}
        </div>
      </div>
    </div>
  );
};

interface TopCollectionProps {
  collection: CollectionInterface;
}

export default TopCollection;
