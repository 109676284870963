import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { get } from "../../../fetch/fetchApi";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";
import Loader from "../../loader/loader";
import TokenDetailsPage from "../tokenDetailsPage";

const TokenContainer: FC = () => {
  const { collectionId, tokenId } = useParams();
  const [token, setToken] = useState<Token>();
  const [collection, setCollection] = useState<CollectionInterface>();

  const { data: collectionData } = useQuery(
    ["collections", collectionId],
    () => get(`collection/v1/header/${collectionId?.toLowerCase()}`),
    {
      enabled: !!collectionId,
    }
  );

  const { data: tokenData, isLoading: tokenIsLoading } = useQuery(
    ["get-token-by-collection-id", collectionId?.toLowerCase(), tokenId],
    () => get(`tokens/v1/${collectionId?.toLowerCase()}/${tokenId}`),
    {
      enabled: !!(collectionId && tokenId && collectionData),
    }
  );

  useEffect(() => {
    if (tokenData) {
      const getMetadataByToken = async () => {
        //venus collection
        let fullSize = true;
        const metadata = await get(
          `tokens/v1/metadata/${collectionId}/${tokenId}?full_size=${fullSize}`
        );

        tokenData.metadata = metadata;
        if (tokenData?.listing) {
          tokenData.listing.token_id = tokenData.token_id;
        }
        if (tokenData?.offers.length > 0) {
          tokenData?.offers.forEach((element: any) => {
            element.token_id = tokenData.token_id;
          });
        }
        setToken(tokenData);
      };
      getMetadataByToken();
    }
  }, [tokenData]);

  useEffect(() => {
    if (collectionData) {
      setCollection(collectionData);
    }
  }, [collectionData]);

  return (
    <>
      {!(token && collection) ? (
        <div style={{ width: "100%", height: "100%", marginTop: "20rem" }}>
          <Loader />
        </div>
      ) : (
        <TokenDetailsPage token={token} collection={collection} />
      )}
    </>
  );
};

export default TokenContainer;
