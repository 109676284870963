import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FC } from "react";
import { CollectionInterface } from "../../interfaces/collection";
import TableCell from "@mui/material/TableCell";
import { Tooltip } from "@mui/material";
import { useFetchEthUsdPrice } from "../../queries/ethDollar";
import "./collection.scss";
import { useNavigate } from "react-router-dom";
import { convertWeiToETH } from "../../utils/web3Utils";

const Collections: FC<CollectionsProps> = (props: CollectionsProps) => {
  const { collections } = props;
  const navigate = useNavigate();

  return (
    <TableContainer component={Paper} sx={{ background: "transparent" }}>
      <Table stickyHeader aria-label="collections stats">
        <TableHead>
          <TableRow>
            <TableCell sx={{ textAlign: "left !important" }}>Name</TableCell>
            <TableCell>volume</TableCell>
            <TableCell>SALES</TableCell>
            <TableCell>FlOOR PRICE</TableCell>
            <TableCell>Top Bid</TableCell>
            <TableCell>Supply</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collections.map((collection, i) => (
            <TableRow key={i}>
              <TableCell
                className="collection-image-cell"
                onClick={() =>
                  navigate(`/collection/${collection.collection_id}`)
                }
              >
                <img
                  className="collection-image"
                  src={
                    collection.image_url
                      ? collection.image_url
                      : "/noContent.svg"
                  }
                  alt=""
                />
                {collection.name}
                <Tooltip title={collection.name} placement="top">
                  <div></div>
                </Tooltip>
              </TableCell>
              <TableCell>
                {collection.volume ? convertWeiToETH(collection.volume) : 0}
              </TableCell>
              <TableCell>{collection.sales ? collection.sales : 0}</TableCell>
              <TableCell>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div> {convertWeiToETH(collection?.floor_price)}</div>
                  <img src="/eth.svg" alt="" />
                </div>
              </TableCell>
              <TableCell>
                <div> {convertWeiToETH(collection?.floor_price)} ETH</div>
                <div className="grey-font-usd">{collection.best_offer}</div>
              </TableCell>
              <TableCell>
                <div className="grey-font-usd">{collection.total_tokens}</div>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

interface CollectionsProps {
  collections: CollectionInterface[];
}

export default Collections;
