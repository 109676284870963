import { FC, LegacyRef, useEffect, useState } from "react";
import {
  displayLeftAuctionTime,
  handleImageCard,
  handleImageCardOverlay,
  handleTokenName,
} from "../../utils/util";
import { useNavigate } from "react-router-dom";
import { Token } from "../../interfaces/token";
import { Action, ActionType } from "../../interfaces/nftActions";
import "./tokenCard.scss";
import { convertWeiToETH } from "../../utils/web3Utils";
import { CollectionInterface } from "../../interfaces/collection";

const TokenCard: FC<TokenCardProps> = (props: TokenCardProps) => {
  const { token, onClick, actions, refFunction, collection } = props;
  const [showActions, setShowActions] = useState<boolean>(false);
  const [offerOrListing, setOfferOrListing] = useState<any>();
  const [isListing, setIsListing] = useState<boolean>(false);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [isVideo, setIsVideo] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleOnClick = () => {
    if (actions) {
      setShowActions(true);
    }
  };

  const handleActionOnClick = (action: Action) => {
    if (action.type === ActionType.List) {
      navigate(`/list/${token.collection_id}/nft/${token.token_id}`);
    } else if (action.type === ActionType.Transfer) {
      navigate(`/collection/${token.collection_id}/nft/${token.token_id}`);
    }
    setShowActions(false);
  };

  useEffect(() => {
    if (token.listing) {
      setOfferOrListing(token.listing);
      setIsListing(true);
    } else if (token.offers && token.offers.length > 0) {
      let highestPriceOffer = token.offers[0];
      token.offers.forEach((offer) => {
        if (offer.price > highestPriceOffer.price) {
          highestPriceOffer = offer;
        }
      });
      setOfferOrListing(highestPriceOffer);
    }
  }, []);

  useEffect(() => {
    if (token.metadata) {
      setIsImage(token.metadata.image.includes("/image/upload/"));
      setIsVideo(token.metadata.image.includes("/video/upload/"));
    }
  }, []);

  return (
    <div className="nft-card-wrapper">
      <div
        className="nft-card-image"
        onClick={() => onClick()}
        ref={refFunction}
      >
        {!handleImageCardOverlay(token) && (
          <div style={{ top: 0, position: "absolute", height: "100%" }}>
            <div className="hovered-card"></div>
            <div
              style={{
                zIndex: 200,
                marginTop: "7rem",
                marginLeft: "1.5rem",
                textAlign: "center",
                position: "relative",
              }}
            >
              Content is not available
            </div>
          </div>
        )}
        {isImage && (
          <>
            <div className="image-overly"></div>
            <img
              src={handleImageCard(token, collection)}
              alt=""
              className="nft-image"
            />
          </>
        )}
        {isVideo && (
          <video
            controls
            style={{ borderRadius: "32px", width: "14rem", height: "16rem" }}
          >
            <source src={token.metadata?.image} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className="nft-card-details">
        <div className="nft-card-token-name">{handleTokenName(token)}</div>

        {offerOrListing && (
          <div className="nft-card-details-price-buy-time">
            <div className="left-nft-card-details-price-buy-time">
              <div className="nft-card-price-wrapper">
                <div className="card-price-const">
                  {isListing ? "PRICE" : "OFFER"}
                </div>
                <img src="/smallEth.svg" alt="" />
                <div className="card-price">
                  {offerOrListing?.price
                    ? convertWeiToETH(offerOrListing.price)
                    : 0}
                </div>
              </div>
              <div className="nft-card-time">
                Ends in:{" "}
                {displayLeftAuctionTime(
                  offerOrListing.start_time,
                  offerOrListing.end_time
                )}
              </div>
            </div>
            {/* <div className="right-nft-card-details-price-buy-time">
              <div className="nft-add-cart">
                <img src="/cart.svg" alt="" />
              </div>
            </div> */}
          </div>
        )}

        {actions && (
          <div className="nft-actions" onClick={handleOnClick}>
            <img src="/dots.svg" alt="" />
          </div>
        )}
        {actions && showActions && (
          <div className="actions-wrapper">
            {actions.map((action, i) => (
              <div
                className="action"
                onClick={() => handleActionOnClick(action)}
                key={i}
              >
                <div className="text">{action.title}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

interface TokenCardProps {
  collection?: CollectionInterface;
  token: Token;
  onClick: Function;
  actions?: Action[];
  refFunction?: LegacyRef<HTMLDivElement>;
}

export default TokenCard;
