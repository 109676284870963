import { FC } from "react";
import { dateDisplay, handleBannerImage } from "../../utils/util";
import { CollectionInterface } from "../../interfaces/collection";
import "./collectionHeader.scss";
import { convertWeiToETH } from "../../utils/web3Utils";

const CollectionHeader: FC<CollectionProps> = (props: CollectionProps) => {
  const { collection, refreshCollection, watchCollection, isWatchedByMe } =
    props;

  const calculateNumberOfTokens = () => {
    if (collection) {
      if (collection.total_tokens == 10000) {
        return collection.total_tokens.toString().slice(0, 2) + "K";
      }
      if (collection.total_tokens == 1000) {
        return collection.total_tokens.toString().slice(0, 1) + "K";
      }
      return collection.total_tokens;
    }
  };

  return (
    <div className="collection-page-wrapper">
      <div className="banner-icon-wrapper">
        <div className="banner">
          <img
            src={handleBannerImage(collection)}
            alt=""
            className="banner-image"
          />
          <div className="banner-overlay"></div>
        </div>
        <div className="icon">
          <img
            src={
              collection?.image_url ? collection?.image_url : "/noContent.svg"
            }
            alt=""
            className="small-image"
          />
        </div>
      </div>

      <div className="collection-page-details">
        <div className="name-collection-page">{collection?.name}</div>
        <div className="items-created-chain">
          <div className="items-wrapper">
            <div className="items">{calculateNumberOfTokens()}</div>
            <div className="items-const">ITEMS</div>
          </div>
          <div className="created-wrapper">
            <div className="created">{dateDisplay(collection.created_at)}</div>
            <div className="created-const">CREATED</div>
          </div>
          <div className="chain-wrapper">
            <div className="chain">{collection?.token_type}</div>
            <div className="chain-const">Type</div>
          </div>
          <div className="chain-wrapper">
            <div className="chain">ETH</div>
            <div className="chain-const">CHAIN</div>
          </div>
        </div>
        {collection?.description && (
          <div className="description">
            {collection?.description?.slice(0, 250) + "..."}
          </div>
        )}
        <div className="tech-details">
          <div className="volume-wrapper">
            <div className="volume">
              {collection?.volume ? convertWeiToETH(collection.volume) : 0}
            </div>
            <div className="volume-const">TOTAL VOLUME</div>
          </div>
          <div className="floor-price-wrapper">
            <div className="floor-price">
              {convertWeiToETH(collection?.floor_price)}
            </div>
            <div className="floor-price-const">FLOOR PRICE</div>
          </div>
          <div className="offer-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="offer">
                {collection?.best_offer
                  ? convertWeiToETH(collection.best_offer.toString())
                  : "--"}
              </div>
              <img src="/smallEth.svg" alt="" />
            </div>

            <div className="offer-const">BEST OFFER</div>
          </div>
          <div className="offer-wrapper">
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="offer">
                {collection?.best_collection_offer
                  ? convertWeiToETH(collection.best_collection_offer.toString())
                  : "--"}
              </div>
              <img src="/smallEth.svg" alt="" />
            </div>

            <div className="offer-const">BEST COLLECTION OFFER</div>
          </div>
          <div className="listed-wrapper">
            <div className="listed">
              {collection?.listing_percentage
                ? (parseFloat(collection.listing_percentage) * 100).toFixed(2) +
                  "%"
                : 0 + "%"}
            </div>
            <div className="listed-const">LISTED</div>
          </div>
          <div className="owners-wrapper">
            <div className="owners">
              {(collection.unique_owners_percentage * 100).toFixed(1)}%
            </div>
            <div className="owners-const">Owners</div>
          </div>
          <div className="buttons">
            <div
              className="refresh-collection-wrapper"
              onClick={async () =>
                await refreshCollection(collection.collection_id)
              }
            >
              <div className="refresh-collection"></div>
              <img src="/refresh.svg" alt="" style={{ position: "absolute" }} />
            </div>
            <div
              className="watch-collection-wrapper"
              onClick={async () =>
                await watchCollection(collection.collection_id)
              }
            >
              <div
                className={
                  isWatchedByMe ? "watched-collection" : "watch-collection"
                }
              ></div>
              <img src="/star.svg" alt="" style={{ position: "absolute" }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CollectionProps {
  collection: CollectionInterface;
  refreshCollection: Function;
  watchCollection: Function;
  isWatchedByMe: boolean;
}

export default CollectionHeader;
