import { QueryCache, QueryClient } from "react-query";

const UseQueryClient = (): QueryClient => {
  const queryClient = new QueryClient({
    queryCache: new QueryCache(),
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  return queryClient;
};

export default UseQueryClient;
