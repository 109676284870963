import { FC, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { get } from "../../fetch/fetchApi";
import { CollectionInterface } from "../../interfaces/collection";
import TopCollections from "./topCollections";

const TopCollectionsContainer: FC = () => {
  const [collections, setCollections] = useState<CollectionInterface[]>([]);

  const { data, isLoading } = useQuery(["collections"], () =>
    get("collections/v1/top?interval=all")
  );

  useEffect(() => {
    if (!data) return;

    setCollections(data);
  }, [data]);

  return <TopCollections collections={collections} isLoading={isLoading} />;
};

export default TopCollectionsContainer;
