import { FC } from "react";
import Header from "../header/header";
import TopCollectionsContainer from "../topCollections/topCollectionsContainer";
import "./homePage.scss";

const HomePage: FC = () => {
  return (
    <>
      <Header />
      <TopCollectionsContainer />
    </>
  );
};

export default HomePage;
