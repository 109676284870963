import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import "./swapEthWethDialog.scss";
import {
  Button,
  DialogActions,
  InputBase,
  InputAdornment,
  Divider,
  CircularProgress,
} from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const SwapETHDialog: FC<SwapETHDialogProps> = (props: SwapETHDialogProps) => {
  const {
    showDialog,
    setShowSwapDialog,
    userBalanceETH,
    userBalanceWETH,
    handleConvert,
    loader,
  } = props;
  const [wethAmount, setWethAmount] = useState<number>(0);
  const [ethAmount, setEthAmount] = useState<number>(0);

  const convertETH = (event: any) => {
    setWethAmount(event.target.value);
  };

  const convertWETH = (event: any) => {
    setEthAmount(event.target.value);
  };

  const handleClickOpen = () => {
    setShowSwapDialog(true);
  };
  const handleClose = () => {
    setShowSwapDialog(false);
  };

  const handleSwap = async () => {
    if (userBalanceETH < "0") {
      //display error
    }
    await handleConvert(wethAmount, ethAmount);
    handleClose();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Swap
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="swap-container">
            <div className="swap-convert-from-to">
              <div className="text-linear-title-small">Convert from</div>
              <div className="balance">Balance {userBalanceETH}</div>
              <div className="input-amount-eth-weth">
                <InputBase
                  sx={{
                    width: "100%",
                    fontFamily: "LemonMilk",
                    marginLeft: "1rem",
                  }}
                  placeholder="Price"
                  value={wethAmount}
                  onChange={convertETH}
                  endAdornment={
                    <InputAdornment
                      sx={{ color: "white", marginRight: "1.5rem" }}
                      position="end"
                    >
                      <div>ETH</div>
                    </InputAdornment>
                  }
                />
              </div>
            </div>
            <Divider light className="divider" />
            <div className="swap-convert-from-to">
              <div className="text-linear-title-small">Convert to</div>
              <div className="balance">Balance {userBalanceWETH} WETH</div>
              {/* <div className="input-amount-eth-weth">
                <InputBase
                  sx={{
                    width: "100%",
                    fontFamily: "LemonMilk",
                    marginLeft: "1rem",
                  }}
                  placeholder="Price"
                  value={ethAmount}
                  onChange={convertWETH}
                  endAdornment={
                    <InputAdornment
                      sx={{ color: "white", marginRight: "1.5rem" }}
                      position="end"
                    >
                      <div>WETH</div>
                    </InputAdornment>
                  }
                />
              </div> */}
            </div>
            <Divider light className="divider" />

            {loader ? (
              <div className="enable-weth-progress">
                <CircularProgress
                  color="inherit"
                  sx={{
                    width: "15px !important",
                    height: "15px !important",
                    size: "10px",
                    marginRight: "0.5rem",
                  }}
                />
                <div className="const-text">Swapping in progress</div>
              </div>
            ) : (
              <div className="swap-sumamry">
                <div className="text-linear-title-small">SUMMARY</div>
                <div className="grey-font-usd" style={{ fontSize: "12px" }}>
                  Rate: 1 ETH = 1 WETH{" "}
                </div>
                <div className="grey-font-usd" style={{ fontSize: "12px" }}>
                  Platform Fees: 0
                </div>
              </div>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="gradient-btn"
            autoFocus
            sx={{ marginBottom: "1rem" }}
            onClick={async () => await handleSwap()}
          >
            Swap
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface SwapETHDialogProps {
  setShowSwapDialog: Function;
  showDialog: boolean;
  userBalanceETH: string;
  userBalanceWETH: string;
  handleConvert: Function;
  loader: boolean;
}
export default SwapETHDialog;
