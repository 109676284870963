import { FC, useState } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import "./connectWallet.scss";
import { Checkbox, DialogActions } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const ConnectWallet: FC<ConnectWalletProps> = (props: ConnectWalletProps) => {
  const { connect, open, setShowWalletDialog } = props;
  const [allowConnectWallet, setAllowConnectWallet] = useState<boolean>(false);

  const handleClose = () => {
    setShowWalletDialog(false);
  };

  const handleChange = () => {
    setAllowConnectWallet(!allowConnectWallet);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Connect Wallet
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
          }}
        >
          <Typography gutterBottom>
            <img
              src="/metamask.svg"
              style={{ height: 100, width: 100 }}
              alt=""
              onClick={() => connect()}
            />
          </Typography>
          <Typography className="dialog-terms">
            By connecting your wallet, you are approve our terms of service,
            Please read them before connecting a wallet
          </Typography>
          <Typography className="dialog-terms">
            <Checkbox style={{ color: "white" }} onChange={handleChange} />
            <p>
              I agree to the <a href={"/terms"}>terms of service</a>
            </p>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            className={
              !allowConnectWallet ? "gradient-btn-disabled" : "gradient-btn"
            }
            autoFocus
            disabled={!allowConnectWallet}
            sx={{ marginBottom: "1rem" }}
            onClick={async () => connect()}
          >
            Connect wallet
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface ConnectWalletProps {
  connect: Function;
  setShowWalletDialog: Function;
  open: boolean;
}
export default ConnectWallet;
