import { FC, useState } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Divider, CircularProgress } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import { CollectionInterface } from "../../../interfaces/collection";
import { Token } from "../../../interfaces/token";
import {
  displayLeftAuctionTime,
  handleImageCard,
  handleTokenName,
} from "../../../utils/util";
import { Offer } from "../../../interfaces/OfferTypes";
import "./makeOfferStepsDialog.scss";
import { convertWeiToETH } from "../../../utils/web3Utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
    background: "rgba(20, 13, 71, 1)",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
    background: "rgba(20, 13, 71, 1)",
  },
  "&.  .MuiDialogTitle-root": {
    background: "rgba(20, 13, 71, 1)",
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} className="dialog-title" {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const MakeCollectionOfferStepsDialog: FC<MakeOfferProps> = (
  props: MakeOfferProps
) => {
  const {
    token,
    setShowMakeOfferStepsDialog,
    open,
    collection,
    isApprovedWethSpending,
    isConfirmOrder,
    isOrderApproved,
    offer,
    isCollectionOffer,
  } = props;
  const [error, setError] = useState(false);

  const handleClickOpen = () => {
    setShowMakeOfferStepsDialog(true);
  };
  const handleClose = () => {
    setShowMakeOfferStepsDialog(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {isCollectionOffer ? "Make Collection Offer" : "Make Offer"}
        </BootstrapDialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center ",
            padding: "4rem",
          }}
        >
          <div className="offer-container">
            <div className="offer-name-details-wrapper">
              <div className="offer-nft-name-icon-wrapper">
                <div className="offer-nft-icon">
                  <img src={collection.image_url} width="100" height="100" />
                </div>

                <div className="nft-name-collection-wrapper">
                  {token && (
                    <div className="offer-nft-name">
                      {handleTokenName(token)}
                    </div>
                  )}
                  <div className="offer-nft-collection">{collection.name}</div>
                </div>
              </div>
              <div className="gray-small-font">
                <div className="offer-nft-price-eth">
                  Offering with: {convertWeiToETH(offer.price.toString())}
                </div>
                <div className="offer-nft-price-eth">
                  Valid until:
                  {displayLeftAuctionTime(offer.startTime, offer.endTime)}
                </div>
              </div>
            </div>

            <Divider light className="divider" />
            <div className="offer-summary">
              {!isOrderApproved ? (
                <>
                  <div className="duration-const">Steps</div>
                  <div className="enable-weth-progress">
                    {!isApprovedWethSpending ? (
                      <CircularProgress
                        color="inherit"
                        sx={{
                          width: "15px !important",
                          height: "15px !important",
                          size: "10px",
                          marginRight: "0.5rem",
                        }}
                      />
                    ) : (
                      <CheckIcon />
                    )}

                    <div className="const-text">Enable WETH spending</div>
                  </div>
                  <div className="confirm-order">
                    {!isConfirmOrder ? (
                      <CircularProgress
                        color="inherit"
                        sx={{
                          width: "15px !important",
                          height: "15px !important",
                          size: "10px",
                          marginRight: "0.5rem",
                        }}
                      />
                    ) : (
                      <CheckIcon />
                    )}
                    <div className="const-text">Confirm Order</div>
                  </div>

                  <div className="const-text">
                    Please confirm the transaction in your wallet.
                  </div>
                </>
              ) : (
                <div className="order-approved-wrapper">
                  <div className="order-approved-title">Offer Made Yay!</div>
                  <div className="order-approved-value"></div>
                  <Divider light className="divider" />
                  <div className="const-text">
                    If a seller accepts your offer, it’ll be automatically
                    withdrawn from all other items and your purchase will be
                    finalized. Keep enough WETH in your wallet to pay, or the
                    seller won’t be able to accept your offer.
                  </div>
                  <div className="offer-approved-validate"></div>
                </div>
              )}
            </div>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

interface MakeOfferProps {
  token?: Token;
  collection: CollectionInterface;
  offer: Offer;
  setShowMakeOfferStepsDialog: Function;
  open: boolean;
  isApprovedWethSpending: boolean;
  setIsApprovedWethSpending: Function;
  isConfirmOrder: boolean;
  setIsConfirmOrder: Function;
  isOrderApproved: boolean;
  isCollectionOffer: boolean;
}
export default MakeCollectionOfferStepsDialog;
