import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./collectionsSkeleton.scss";

const CollectionsSkeleton: FC = () => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap" }}>
      {Array.from({ length: 15 }, (_, index) => (
        <div className="card-collection-skeleton" key={index}>
          <div className="left-col-collection">
            <Skeleton
              circle
              count={1}
              width={60}
              height={60}
              style={{ marginTop: "0.2rem" }}
            />
          </div>
          <div className="right-col">
            <Skeleton count={3} style={{ marginTop: "0.6rem" }} width={200} />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CollectionsSkeleton;
