export enum ActionType {
  List = 0,
  Transfer = 1,
  Edit = 2,
}

export interface Action {
  type?: ActionType;
  action?: Function;
  title: string;
}
